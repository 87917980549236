import { createSelector, createSlice } from '@reduxjs/toolkit'
import { TablistPageType } from '../models/tablist_pages.types'
import { ReduxStateType } from './baseStore'
import { ProjectPageType } from '../models/saved_sessions.types'
import { handleSelection } from '../utils/utils'

export type TablistPageTypeWithOrder = TablistPageType & {
  order: number
}

export type SelectedTablistPagesType = {
  [key: string]: TablistPageTypeWithOrder
}

export type SelectedProjectPagesType = {
  [key: string]: ProjectPageType
}

export interface SelectedPagesSliceStateType {
  selectedSavedForLaterPages: SelectedTablistPagesType
  selectedSmartSessionPages: SelectedTablistPagesType
  selectedProjectPages: SelectedProjectPagesType
  lastSelectedItemId: string | null
}

const initialState: SelectedPagesSliceStateType = {
  selectedSavedForLaterPages: {},
  selectedSmartSessionPages: {},
  selectedProjectPages: {},
  lastSelectedItemId: null,
}

export const selectedPagesSlice = createSlice({
  name: 'selectedPages',
  initialState,
  reducers: {
    removeSelectedSavedForLaterPages: (
      state,
      action: {
        payload: string[]
      },
    ) => {
      const newObject = { ...state.selectedSavedForLaterPages }
      action.payload.forEach((t) => {
        delete newObject[t]
      })
      state.selectedSavedForLaterPages = newObject
    },
    handleSavedForLaterPageSelection: (
      state,
      action: {
        payload: {
          pages: TablistPageType[] // The list of items
          clickedItemId: string // The ID of the clicked item
          isShiftKey: boolean // Whether the Shift key is held
          isCtrlOrCmdKey: boolean // Whether the Ctrl or Cmd key is held
        }
      },
    ) => {
      const { newSelectedItems, newLastSelectedItemId } = handleSelection<TablistPageType>({
        items: action.payload.pages,
        clickedItemId: action.payload.clickedItemId,
        selectedItems: state.selectedSavedForLaterPages,
        lastSelectedItemId: state.lastSelectedItemId,
        isShiftKey: action.payload.isShiftKey,
        isCtrlOrCmdKey: action.payload.isCtrlOrCmdKey,
      })

      state.selectedSavedForLaterPages = newSelectedItems
      state.lastSelectedItemId = newLastSelectedItemId
    },
    removeSelectedSmartSessionPages: (
      state,
      action: {
        payload: string[]
      },
    ) => {
      const newObject = { ...state.selectedSmartSessionPages }
      action.payload.forEach((t) => {
        delete newObject[t]
      })
      state.selectedSmartSessionPages = newObject
    },
    handleSmartSessionPageSelection: (
      state,
      action: {
        payload: {
          pages: TablistPageType[] // The list of items
          clickedItemId: string // The ID of the clicked item
          isShiftKey: boolean // Whether the Shift key is held
          isCtrlOrCmdKey: boolean // Whether the Ctrl or Cmd key is held
        }
      },
    ) => {
      const { newSelectedItems, newLastSelectedItemId } = handleSelection<TablistPageType>({
        items: action.payload.pages,
        clickedItemId: action.payload.clickedItemId,
        selectedItems: state.selectedSmartSessionPages,
        lastSelectedItemId: state.lastSelectedItemId,
        isShiftKey: action.payload.isShiftKey,
        isCtrlOrCmdKey: action.payload.isCtrlOrCmdKey,
      })

      state.selectedSmartSessionPages = newSelectedItems
      state.lastSelectedItemId = newLastSelectedItemId
    },
    removeSelectedProjectPages: (
      state,
      action: {
        payload: string[]
      },
    ) => {
      const newObject = { ...state.selectedProjectPages }
      action.payload.forEach((t) => {
        delete newObject[t]
      })
      state.selectedProjectPages = newObject
    },
    handleProjectPageSelection: (
      state,
      action: {
        payload: {
          pages: ProjectPageType[] // The list of items
          clickedItemId: string // The ID of the clicked item
          isShiftKey: boolean // Whether the Shift key is held
          isCtrlOrCmdKey: boolean // Whether the Ctrl or Cmd key is held
        }
      },
    ) => {
      const { newSelectedItems, newLastSelectedItemId } = handleSelection<ProjectPageType>({
        items: action.payload.pages,
        clickedItemId: action.payload.clickedItemId,
        selectedItems: state.selectedProjectPages,
        lastSelectedItemId: state.lastSelectedItemId,
        isShiftKey: action.payload.isShiftKey,
        isCtrlOrCmdKey: action.payload.isCtrlOrCmdKey,
      })

      state.selectedProjectPages = newSelectedItems
      state.lastSelectedItemId = newLastSelectedItemId
    },
    clearSelectedPages: (state) => {
      state.selectedSavedForLaterPages = {}
      state.selectedSmartSessionPages = {}
      state.selectedProjectPages = {}
    },
  },
})

export const selectSelectedSavedForLaterPages = (state: ReduxStateType) =>
  state.selectedPages.selectedSavedForLaterPages
export const selectSelectedSmartSessionPages = (state: ReduxStateType) =>
  state.selectedPages.selectedSmartSessionPages
export const selectSelectedProjectPages = (state: ReduxStateType) =>
  state.selectedPages.selectedProjectPages

export const selectAllSelectedPages = createSelector(
  [selectSelectedSavedForLaterPages, selectSelectedSmartSessionPages, selectSelectedProjectPages],
  (savedForLaterPages, smartSessionPages, projectPages) => {
    return { ...savedForLaterPages, ...smartSessionPages, ...projectPages }
  },
)

export const selectNumSelectedPages = createSelector(
  [selectSelectedSavedForLaterPages, selectSelectedSmartSessionPages, selectSelectedProjectPages],
  (savedForLaterPages, smartSessionPages, projectPages) => {
    return (
      Object.keys(savedForLaterPages).length +
      Object.keys(smartSessionPages).length +
      Object.keys(projectPages).length
    )
  },
)

export const {
  removeSelectedSavedForLaterPages,
  handleSavedForLaterPageSelection,
  removeSelectedSmartSessionPages,
  handleSmartSessionPageSelection,
  removeSelectedProjectPages,
  handleProjectPageSelection,
  clearSelectedPages,
} = selectedPagesSlice.actions

export default selectedPagesSlice.reducer
