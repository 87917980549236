import React, { FC } from 'react'
import { Heading, IconButton, Tooltip, Flex } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { MdArrowOutward } from 'react-icons/md'

const ButtonsContainer = styled.div`
  height: 22px;
  position: absolute;
  right: 0;
  top: 0;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-left: 8px;
  background: #f6f6f6;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    width: 24px;
    height: 100%;
    background-image: 'linear-gradient(to right, rgba(246, 246, 246, 0), #f6f6f6)';
  }
`

interface Props {
  title: string
  isArchived: boolean
  isViewOnly: boolean
  handleClickOpenIcon?: (event: React.MouseEvent<Element, MouseEvent>) => void
}

const ProjectDetailSharedTitle: FC<Props> = (props) => {
  const { title, isArchived, isViewOnly, handleClickOpenIcon } = props

  return (
    <Flex position="relative" width="100%" alignItems="center" overflow="hidden">
      <Heading as="h1" fontWeight={600} fontSize="16px" lineHeight="24px">
        {title}
      </Heading>
      <Flex ml="8px" gap="8px" alignItems="center">
        {isArchived && (
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="24px"
            p="4px 12px"
            fontWeight={500}
            fontSize="12px"
            lineHeight="16px"
            color="#585858"
            bg="#E0E0E0"
          >
            Archived
          </Flex>
        )}

        {isViewOnly && (
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="24px"
            p="4px 12px"
            fontWeight={500}
            fontSize="12px"
            lineHeight="16px"
            color="#585858"
            bg="#E0E0E0"
            whiteSpace="nowrap"
          >
            View only
          </Flex>
        )}
      </Flex>
      <ButtonsContainer>
        {!!handleClickOpenIcon && (
          <span className="flex-center" style={{ height: '100%', cursor: 'pointer' }}>
            <Tooltip label={`Open all pages`} placement="top">
              <IconButton
                variant="outline"
                border="none"
                aria-label={`Open all pages`}
                as="a"
                icon={<MdArrowOutward color="#585858" />}
                onClick={handleClickOpenIcon}
                height="24px"
                width="24px"
                minWidth="24px"
                tabIndex={-1}
                _hover={{
                  background: 'inherit',
                }}
              />
            </Tooltip>
          </span>
        )}
      </ButtonsContainer>
    </Flex>
  )
}

export default ProjectDetailSharedTitle
