import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  Collapse,
  Box,
  ToastId,
  UseToastOptions,
  useToast,
  Flex,
  Heading,
  Icon,
} from '@chakra-ui/react'
import { FiChevronDown } from 'react-icons/fi'
import PutasideTabController from './PutasideTabController'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { styled } from 'styled-components'
import FavIconList, { FavIconListItemType } from '../FavIconList'
import { useUserContext } from '../../../contexts/UserContext'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { selectSearchQueryValue } from '../../../redux/searchSlice'
import {
  useAppendTablistPagesMutation,
  useGetTablistPagesQuery,
} from '../../../redux/services/skeema/tablist_pages.endpoints'
import { cx, css } from '@emotion/css'
import SmartSessionDashboard from '../SmartSessionDashboard'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'
import { MdOutlineInbox, MdOutlineViewAgenda } from 'react-icons/md'
import SavedForLaterList from './SavedForLaterList'
import { useDeleteOpenTabsMutation, useGetOpenTabsQuery } from '../../../webapp/redux/extension'
import NotificationToast from '../../../components/NotificationToast'
import { GLOBAL_WINDOW_MESSAGE_ENUM, onGlobalWindowMessage } from '../../../utils/utils'
import { clearSelectedPages } from '../../../redux/selectedPagesSlice'
import { useLocation } from 'react-router-dom'

export interface SelectedTablistPageType {
  [id: string]: TablistPageType
}

export enum TABLIST_AREA_NAME_ENUM {
  MostUsed = 'most_used_tabs',
  Open = 'open_tabs',
  RecentlySaved = 'other_recently_used_tabs',
  SearchResults = 'search_results',
  SmartSessions = 'smart_sessions',
  FeedHistory = 'feed_history',
}

const SectionContainer = styled.div`
  margin-bottom: 16px;
`

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-left: 12px;
`

const TableHeaderCell = styled.div<{ $isClickable?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};

  h2 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
`

const CollapseIcon = styled.span<{ $isCollapsed: boolean }>`
  height: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.2em;
  font-weight: bold;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 8px;
  width: 16px;
  color: black;

  & > svg {
    display: flex;
    transform: ${({ $isCollapsed }) => `rotate(${$isCollapsed ? -90 : 0}deg)`};
    transition: transform 0.5s;
    transform-origin: 50% 50%;
  }
`

const onboardingPrepAnimationClassName = css`
  transition: opacity 2000ms ease-in-out 500ms;
  opacity: 0;
`

const onboardingAnimationClassName = css`
  opacity: 1;
`

const TOAST_DURATION_MS = 3000
const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

export enum PUTASIDE_TABLIST_VIEW_ENUM {
  SESSIONS = 'SESSIONS',
  SAVED_TABS = 'SAVED_TABS',
}

interface Props {
  defaultView: PUTASIDE_TABLIST_VIEW_ENUM
  isOnboardingTutorialEnabled?: boolean
  isSmartSessionOnboardingActive?: boolean
  isSavedTabsOnboardingStepActive?: boolean
}

const PutasideTabList: FC<Props> = (props) => {
  const {
    defaultView,
    isOnboardingTutorialEnabled = false,
    isSmartSessionOnboardingActive = false,
    isSavedTabsOnboardingStepActive = false,
  } = props
  const { captureAnalytics } = useUserContext()
  const dispatch = useReduxDispatch()

  const [_activeView, setActiveView] = useState<
    PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS | PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
  >(defaultView)
  const activeView = isSmartSessionOnboardingActive
    ? PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS
    : isSavedTabsOnboardingStepActive
      ? PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
      : _activeView

  const { hash } = useLocation()
  const shouldRetrieveHighlightedPages = hash === '#highlighted_tabs'
  useEffect(() => {
    if (shouldRetrieveHighlightedPages) {
      setActiveView(PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS)
    }
  }, [shouldRetrieveHighlightedPages])

  useEffect(() => {
    return () => {
      dispatch(clearSelectedPages())
    }
  }, [dispatch, activeView])

  const toast = useToast(USE_TOAST_OPTIONS)
  const toastIdRef = useRef<ToastId | undefined>(undefined)

  const queryValue = useReduxSelector(selectSearchQueryValue)

  const isSearchingActive = queryValue.trim().length > 0
  const isResultsModeEnabled = isSearchingActive

  const [isMostUsedPagesCollapsed, setIsMostUsedPagesCollapsed] = useState<boolean>(true)
  const [isOpenTabsCollapsed, setIsOpenTabsCollapsed] = useState<boolean>(true)

  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(isOnboardingTutorialEnabled)
    }, 500)
  }, [isOnboardingTutorialEnabled])

  const { data: openTabPages } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })
  const [appendTablistPages] = useAppendTablistPagesMutation()
  const [deleteOpenTabs] = useDeleteOpenTabsMutation()
  const deleteOpenTab = useCallback(
    async (params: { id: string }) => {
      await deleteOpenTabs({ ids: [params.id] }).unwrap()
    },
    [deleteOpenTabs],
  )

  const { data: tablistPagesQueryResult } = useGetTablistPagesQuery(undefined)
  const mostVisitedPages = tablistPagesQueryResult?.most_visited_pages

  const saveAndCloseOpenTab = useCallback(
    async (params: { page: TablistPageType }) => {
      const { page } = params
      const id = page.id

      await appendTablistPages({ pages: [page], isManual: true })
        .unwrap()
        .then((res) => {
          if (res.ok) {
            deleteOpenTab({ id })
          }
        })
        .catch((error) => {
          console.error(error)
        })

      if (toastIdRef.current) {
        toast.close(toastIdRef.current)
        toastIdRef.current = undefined
      }
      toastIdRef.current = toast({
        duration: TOAST_DURATION_MS,
        containerStyle: { minWidth: 'auto' },
        render: () => <NotificationToast message={`Tab has been saved to 'All saved tabs'`} />,
      })
    },
    [appendTablistPages, deleteOpenTab, toast],
  )

  const handleClickMostUsedSection = useCallback(() => {
    setIsMostUsedPagesCollapsed((prev) => {
      captureAnalytics('putaside_tab_list:most_used_section_expand', {
        isExpanded: !prev,
      })
      return !prev
    })
  }, [captureAnalytics])

  const handleClickOpenTabsSection = useCallback(() => {
    setIsOpenTabsCollapsed((prev) => {
      captureAnalytics('putaside_tab_list:open_tabs_section_collapse', {
        isCollapsed: !prev,
      })
      return !prev
    })
  }, [captureAnalytics])

  const handleClickExpandFavIcons = useCallback(() => {
    setIsOpenTabsCollapsed(false)
    captureAnalytics('putaside_tab_list:open_tabs_section_collapse', {
      isCollapsed: false,
      isFavIconsClicked: true,
    })
  }, [captureAnalytics])

  const handleClickSessionsTab = useCallback(() => {
    setActiveView((prev) => {
      if (prev !== PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS) {
        captureAnalytics('putaside_tab_list:sessions_tab_click')
      }
      return PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS
    })
  }, [captureAnalytics])

  const handleClickAllSavedTabsTab = useCallback(() => {
    setActiveView((prev) => {
      if (prev !== PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS) {
        captureAnalytics('putaside_tab_list:all_saved_tabs_tab_click')
      }
      return PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
    })
  }, [captureAnalytics])

  useEffect(() => {
    return onGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.SWITCH_TO_SAVED_TABS_VIEW, () => {
      setActiveView(PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS)
    })
  }, [setActiveView])

  return (
    <Box
      className={cx({
        [onboardingPrepAnimationClassName]: isOnboardingTutorialEnabled,
        [onboardingAnimationClassName]: shouldAnimate,
      })}
      width="100%"
      height="100%"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Box p="24px 16px">
        <SearchResultsReplacementWrapper>
          <Collapse in={!isResultsModeEnabled} animateOpacity>
            {mostVisitedPages && mostVisitedPages.length > 0 && (
              <SectionContainer>
                <TableHeaderContainer>
                  <TableHeaderCell
                    onClick={
                      isSmartSessionOnboardingActive ? undefined : handleClickMostUsedSection
                    }
                    $isClickable={!isSmartSessionOnboardingActive}
                  >
                    <h2>Most used</h2>
                    <CollapseIcon $isCollapsed={!!isMostUsedPagesCollapsed}>
                      <FiChevronDown />
                    </CollapseIcon>
                  </TableHeaderCell>
                </TableHeaderContainer>

                <Box
                  flex="1 2 auto"
                  display={isMostUsedPagesCollapsed ? 'flex' : 'none'}
                  alignItems="center"
                  justifyContent="flex-start"
                  overflow="auto"
                  minWidth="24px"
                  maxWidth="100%"
                  padding="0 6px"
                >
                  <FavIconList
                    items={mostVisitedPages.map((t) => {
                      return {
                        id: t.id,
                        url: t.url,
                        title: t.title,
                        faviconUrl: t.favicon_url,
                      } as FavIconListItemType
                    })}
                    size="md"
                    showTitleTooltip={true}
                    isClickable={true}
                    areaName={TABLIST_AREA_NAME_ENUM.MostUsed}
                  />
                </Box>

                <Collapse in={!isMostUsedPagesCollapsed} animateOpacity>
                  <div>
                    {mostVisitedPages?.map((t, idx) => {
                      return (
                        <PutasideTabController
                          key={t.id}
                          id={t.id}
                          page={t}
                          showTimeString={false}
                          queryValue={queryValue}
                          index={idx}
                          numTotalResults={mostVisitedPages.length}
                          areaName={TABLIST_AREA_NAME_ENUM.MostUsed}
                          isDraggingDisabled={isSmartSessionOnboardingActive}
                          isHoverDisabled={isSmartSessionOnboardingActive}
                          isTitleClickDisabled={isSmartSessionOnboardingActive}
                        />
                      )
                    })}
                  </div>
                </Collapse>
              </SectionContainer>
            )}

            {openTabPages && openTabPages.length > 0 && (
              <SectionContainer>
                <TableHeaderContainer>
                  <TableHeaderCell
                    onClick={
                      isSmartSessionOnboardingActive ? undefined : handleClickOpenTabsSection
                    }
                    $isClickable={!isSmartSessionOnboardingActive}
                  >
                    <h2>Using now</h2>
                    <CollapseIcon $isCollapsed={!!isOpenTabsCollapsed}>
                      <FiChevronDown />
                    </CollapseIcon>
                  </TableHeaderCell>
                </TableHeaderContainer>

                <Box
                  flex="1 2 auto"
                  display={isOpenTabsCollapsed ? 'flex' : 'none'}
                  alignItems="center"
                  justifyContent="flex-start"
                  overflow="auto"
                  minWidth="24px"
                  maxWidth="100%"
                  padding="0 6px"
                >
                  <FavIconList
                    items={openTabPages.map((t) => {
                      return {
                        id: t.id,
                        url: t.url,
                        faviconUrl: t.favicon_url,
                        title: t.title,
                        windowId: t.window_id,
                        isOpen: true,
                        tabId: parseInt(t.id),
                      } as FavIconListItemType
                    })}
                    isClickable={true}
                    areaName={TABLIST_AREA_NAME_ENUM.Open}
                    size="md"
                    showTitleTooltip={true}
                    maxLength={8}
                    onClickExpand={handleClickExpandFavIcons}
                  />
                </Box>

                <Collapse in={!isOpenTabsCollapsed} animateOpacity>
                  <div>
                    {openTabPages?.map((t, idx) => {
                      return (
                        <PutasideTabController
                          key={t.id}
                          id={t.id}
                          page={t}
                          onDeleteOpenTab={deleteOpenTab}
                          onSaveOpenTab={saveAndCloseOpenTab}
                          showTimeString={false}
                          queryValue={''}
                          index={idx}
                          numTotalResults={openTabPages.length}
                          areaName={TABLIST_AREA_NAME_ENUM.Open}
                        />
                      )
                    })}
                  </div>
                </Collapse>
              </SectionContainer>
            )}
            <SectionContainer>
              <Box mb="12px">
                <Flex m="0px 0px" alignItems="center" position="relative" zIndex={2}>
                  <Box cursor="pointer" p="10px 10px 0 10px" onClick={handleClickSessionsTab}>
                    <Flex
                      p="0 2px 6px 2px"
                      alignItems="center"
                      borderBottom="2px solid"
                      borderBottomColor={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS ? '#000' : 'transparent'
                      }
                      color={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS ? '#000' : '#585858'
                      }
                      _hover={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS
                          ? undefined
                          : { borderBottomColor: '#585858' }
                      }
                      transition="all 0.2s"
                      gap="6px"
                    >
                      <Icon as={MdOutlineViewAgenda} width="24px" height="24px" />
                      <Heading
                        as="h2"
                        color="inherit"
                        fontSize="16px"
                        fontWeight={600}
                        lineHeight="24px"
                      >
                        Sessions
                      </Heading>
                    </Flex>
                  </Box>

                  <Box cursor="pointer" p="10px 10px 0 10px" onClick={handleClickAllSavedTabsTab}>
                    <Flex
                      p="0 2px 6px 2px"
                      alignItems="center"
                      borderBottom="2px solid"
                      borderBottomColor={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
                          ? '#000'
                          : 'transparent'
                      }
                      color={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS ? '#000' : '#585858'
                      }
                      _hover={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
                          ? undefined
                          : { borderBottomColor: '#585858' }
                      }
                      transition="all 0.2s"
                      gap="6px"
                    >
                      <Icon as={MdOutlineInbox} width="24px" height="24px" />
                      <Heading
                        as="h2"
                        color="inherit"
                        fontSize="16px"
                        fontWeight={600}
                        lineHeight="24px"
                      >
                        All saved tabs
                      </Heading>
                    </Flex>
                  </Box>
                </Flex>

                <Box
                  borderBottom="1px solid #D5D5D5"
                  margin="0px 8px 0px 10px"
                  position="relative"
                  top="-1px"
                  zIndex={1}
                />
              </Box>

              <Collapse in={activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS} animateOpacity>
                <SmartSessionDashboard isSSOnboardingActive={isSmartSessionOnboardingActive} />
              </Collapse>

              <Collapse in={activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS} animateOpacity>
                <SavedForLaterList />
              </Collapse>
            </SectionContainer>
          </Collapse>
        </SearchResultsReplacementWrapper>
      </Box>
    </Box>
  )
}

export default PutasideTabList
