import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { setAutoFreeze } from 'immer'

import searchReducer from '../../redux/searchSlice'
import userReducer from '../../redux/userSlice'
import dndReducer from '../../redux/dndSlice'
import projectsReducer from '../../redux/projectsSlice'
import tablistReducer from '../../redux/tablistSlice'
import selectedPagesReducer from '../../redux/selectedPagesSlice'
import sessionReducer from '../../redux/sessionSlice'
import { skeemaApi } from '../../redux/services/skeema'
import { extensionMessageApi } from './extension'

// Fixes "Cannot assign to read only property" error message
// when modifying objects from Redux state directly.
// https://github.com/reduxjs/redux-toolkit/issues/424
setAutoFreeze(false)

const webappStore = configureStore({
  reducer: {
    user: userReducer,
    search: searchReducer,
    dnd: dndReducer,
    projects: projectsReducer,
    tablist: tablistReducer,
    selectedPages: selectedPagesReducer,
    session: sessionReducer,
    [skeemaApi.reducerPath]: skeemaApi.reducer,
    [extensionMessageApi.reducerPath]: extensionMessageApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(skeemaApi.middleware).concat(extensionMessageApi.middleware),
})

setupListeners(webappStore.dispatch)

export default webappStore
