import React, { FC, useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { css, styled } from 'styled-components'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../../models/tablist_pages.types'
import { useDrop } from 'react-dnd'
import { DND_ITEM_ENUM, DnDItemPayload } from '../../../models/dnd.types'
import { cx } from '@emotion/css'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { clearSelectedPages } from '../../../redux/selectedPagesSlice'

const Container = styled.div<{
  $isDraggingOver: boolean
  $isDisabled: boolean
}>`
  width: calc(100% - 12px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #000;
  border-radius: 14px;
  padding: 16px;
  margin: 2px 6px 16px 6px;
  cursor: pointer;
  transition:
    border-color 0.2s,
    background-color 0.2s;

  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          pointer-events: none;
          opacity: 0.4;
        `
      : ''}

  .main-content {
    width: 100%;
    display: flex;
    align-items: center;

    & > svg {
      min-height: 18px;
      min-width: 18px;
      height: 24px;
      width: 24px;
      margin-right: 8px;
      color: #fff;
    }

    & > h2 {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 22px;
    }
  }

  .info-label {
    width: 100%;
    align-items: center;
    overflow: hidden;
    max-height: 0px;

    transition: max-height 0.3s;

    p {
      margin-left: 34px;
      margin-top: 8px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      overflow: hidden;
    }

    &.info-label {
      color: #e0e0e0;
    }
  }

  &:hover,
  &.force-hover {
    background-color: #585858;

    ${({ $isDraggingOver }) =>
      $isDraggingOver
        ? css`
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%230071e3' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");
            background-color: rgba(46, 69, 92);
          `
        : ''}

    .info-label {
      max-height: 50px;
    }
  }
`
interface Props {
  handleCreateProject: (params: {
    tablistPages: TablistPageType[]
    title?: string
    order?: number
    loggingProps?: Record<string, unknown>
  }) => Promise<void>
  handleMovePagesAndCreateProject: (params: {
    tablistPages: TablistPageType[]
    tablistPageIndexes: number[]
    title?: string
    order?: number
    loggingProps?: Record<string, unknown>
  }) => Promise<void>
  isProjectLimitReached: boolean
  isDisabled?: boolean
  handleProjectLimitError: () => void
}

const CreateProjectItem: FC<Props> = (props) => {
  const {
    handleCreateProject,
    handleMovePagesAndCreateProject,
    isProjectLimitReached,
    isDisabled = false,
    handleProjectLimitError,
  } = props
  const dispatch = useReduxDispatch()
  const isTablistPageDragging = useReduxSelector((state) => state.dnd.isTablistPageDragging)
  const isSmartSessionDragging = useReduxSelector((state) => state.dnd.isSmartSessionDragging)
  const shouldForceHoverState = isTablistPageDragging || isSmartSessionDragging
  const [isActiveState, setIsActiveState] = useState(false)

  useEffect(() => {
    if (isActiveState) {
      setTimeout(() => {
        setIsActiveState(false)
      }, 500)
    }
  }, [isActiveState])

  const handleClickContainer = () => {
    if (isProjectLimitReached) {
      handleProjectLimitError()
    } else {
      handleCreateProject({ tablistPages: [] })
    }
  }

  const [{ isDraggingOver }, connectDropTarget] = useDrop(
    () => ({
      accept: [
        DND_ITEM_ENUM.TABLIST_PAGE,
        DND_ITEM_ENUM.TABLIST_PAGES,
        DND_ITEM_ENUM.SMART_SESSION,
      ],
      collect: (monitor) => ({
        isDraggingOver: monitor.isOver(),
      }),
      canDrop: (_payload: DnDItemPayload) => {
        return true
      },
      drop: async (payload: DnDItemPayload) => {
        if (isProjectLimitReached) {
          handleProjectLimitError()
          return { status: 'ERROR: Project limit reached' }
        }

        if (
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES
        ) {
          const payloads =
            payload.type === DND_ITEM_ENUM.TABLIST_PAGES ? payload.payloads : [payload]
          const tablistPages = payloads.map((pl) => pl.page)
          const tablistPageIndexes = payloads.map((pl) => pl.index)

          if (tablistPages[0].entity_type === TABLIST_PAGE_ENUM.RECENTLY_USED) {
            await handleMovePagesAndCreateProject({
              tablistPages,
              tablistPageIndexes,
            })
          } else {
            await handleCreateProject({ tablistPages })
          }
          dispatch(clearSelectedPages())

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.SMART_SESSION) {
          const tablistPages = [...payload.session.pages]
          const title = payload.session.name

          await handleCreateProject({
            tablistPages,
            title,
            loggingProps: {
              session: payload.session,
            },
          })
          return { status: 'SUCCESS' }
        }

        return { status: 'ERROR' }
      },
    }),
    [handleCreateProject, handleProjectLimitError, isProjectLimitReached, dispatch],
  )

  return (
    <Container
      className={cx({ 'force-hover': shouldForceHoverState })}
      $isDisabled={isDisabled}
      $isDraggingOver={isDraggingOver}
      ref={connectDropTarget}
      onClick={handleClickContainer}
    >
      <div className="main-content">
        <MdAdd />
        <h2>New folder</h2>
      </div>
      <div className="info-label">
        <p>{`Drop a tab here or into a folder`}</p>
      </div>
    </Container>
  )
}

export default CreateProjectItem
