import React, { FC } from 'react'
import {
  DND_ITEM_ENUM,
  DnDItemProjectPagesPayload,
  DnDItemTablistPagesPayload,
} from '../../../models/dnd.types'
import DragIcon from '../../../components/DragIcon'
import { Box, Flex, Heading } from '@chakra-ui/react'
import PutasideTabView, {
  PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM,
} from '../PutasideTabList/PutasideTabView'
import { TABLIST_PAGE_ENUM } from '../../../models/tablist_pages.types'

function emptyFunc(_event: React.MouseEvent<HTMLElement, MouseEvent>): void {
  throw new Error('Function not implemented.')
}

interface Props {
  item: DnDItemProjectPagesPayload | DnDItemTablistPagesPayload
}

const PagesDragPreview: FC<Props> = (props) => {
  const { item } = props
  const title = `${item.payloads.length} selected pages`

  return (
    <Box
      position="relative"
      width="272px"
      display="flex"
      flexDirection="column"
      padding="16px 16px 16px 2px"
      borderRadius="12px"
      border="1.5px solid #cfd2ef"
      cursor="grabbing"
      boxSizing="border-box"
      background="#f2f3fe"
      opacity="0.7"
      boxShadow={`0px 4px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)`}
    >
      <Flex
        position="relative"
        width="100%"
        alignItems="center"
        overflow="hidden"
        marginBottom="6px"
      >
        <Box w="12px" mr="6px" className="hover-show-title-icons">
          <DragIcon />
        </Box>
        <Heading
          as="h2"
          color="#000"
          fontSize="14px"
          fontWeight="600"
          wordBreak="break-word"
          lineHeight="22px"
        >
          {title}
        </Heading>
      </Flex>
      <Box>
        {item.payloads.map((payload) => {
          const page = payload.page

          return (
            <PutasideTabView
              key={page.id}
              entityType={TABLIST_PAGE_ENUM.PROJECT_PAGE}
              title={
                page.custom_title && page.custom_title.length > 0
                  ? page.custom_title
                  : page.title ?? 'Untitled Page'
              }
              favIconUrl={page.favicon_url}
              isTitleClickDisabled={true}
              isFocused={false}
              isCheckboxShown={false}
              showTimeString={false}
              showActionIcons={false}
              isDraggingDisabled={true}
              showOpenTabIcon={
                payload.type === DND_ITEM_ENUM.PROJECT_PAGE ? false : payload.page.is_open
              }
              lastAccessDateTime={
                payload.type === DND_ITEM_ENUM.PROJECT_PAGE
                  ? payload.page.last_access_datetime ?? ''
                  : payload.page.last_access_datetime_utc_iso
              }
              handleClickFavIcon={emptyFunc}
              handleClickDragIcon={emptyFunc}
              handleClickTitle={emptyFunc}
              handleClickBody={emptyFunc}
              isDraggingThis={false}
              isSelected={false}
              isTabAboveSelected={false}
              isTabBelowSelected={false}
              isSelectionDisabled={false}
              isHoverDisabled={false}
              spacingVariant={PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM.DEFAULT}
              isDragIconPlaceholderHidden={false}
              shouldShowDeleteIcon={false}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default PagesDragPreview
