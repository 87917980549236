import React, { FC } from 'react'
import { TablistPageType } from '../../../models/tablist_pages.types'
import PutasideTabController from './PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from './PutasideTabList'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { hashString } from '../../../utils/utils'
import { MoveTablistPageInfoType } from '../moveItemMenu/moveItemMenu.types'
import { SelectedTablistPagesType } from '../../../redux/selectedPagesSlice'
import { MdCheck, MdClose } from 'react-icons/md'
import { PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM } from './PutasideTabView'

const TAB_GROUP_COLOR_MAP: {
  [key in chrome.tabGroups.Color]: string
} = {
  grey: 'rgb(85,88,93)',
  blue: 'rgb(43,99,224)',
  red: 'rgb(199,57,39)',
  yellow: 'rgb(239,166,39)',
  green: 'rgb(44,115,53)',
  pink: 'rgb(190,41,120)',
  purple: 'rgb(143,56,237)',
  cyan: 'rgb(37,110,119)',
  orange: 'rgb(239,139,64)',
}

const getRandomColorFromTitle = (title: string) => {
  const colorIndex = Math.abs(hashString(title)) % Object.values(TAB_GROUP_COLOR_MAP).length
  const color = Object.values(TAB_GROUP_COLOR_MAP)[colorIndex]
  return color
}

interface PropTypes {
  key: string
  groupId: number
  index: number
  pages: TablistPageType[]
  title: string
  lastVisitedTime: number
  numTotalResults: number
  highlightedPageIds?: string[]
  selectedPages: SelectedTablistPagesType
  showAiSuggestion?: boolean
  aiSuggestionReason?: string
  onDeleteTablistPage: (params: { page: TablistPageType; index: number }) => Promise<void>
  onOpenPageMoveMenu: (params: { pageInfo: MoveTablistPageInfoType }) => void
  onPageSelected: (params: {
    clickedItemId: string
    isShiftKey: boolean
    isCtrlOrCmdKey: boolean
    isCurrentlySelected: boolean
    extraAnalyticsProps?: Record<string, unknown>
  }) => void
  removePageSelection: (params: { pageId: string }) => void
}

const PutasideTabGroup: FC<PropTypes> = (props) => {
  const {
    pages,
    title,
    numTotalResults,
    highlightedPageIds,
    selectedPages,
    showAiSuggestion,
    aiSuggestionReason,
    onDeleteTablistPage,
    onOpenPageMoveMenu,
    onPageSelected,
    removePageSelection,
  } = props
  const color = getRandomColorFromTitle(title)

  return (
    <Box w="100%" mt="8px" mb="4px">
      <Flex alignItems="center" borderRadius="8px">
        <Box
          w="16px"
          h="16px"
          backgroundColor={color}
          borderRadius="4px"
          marginRight="4px"
          marginLeft="16px"
        />
        <Text as="h3" color="#585858" fontSize="12px" fontWeight="500" lineHeight="16px" mr="8px">
          {title}
        </Text>
        {showAiSuggestion && (
          <Box
            bg="rgba(12, 137, 78, 0.2)"
            color="#0C894E"
            borderRadius="16px"
            h="16px"
            minW="275px"
            p="2px 6px"
            mr="8px"
            display="flex"
            alignItems="center"
          >
            <Text fontSize="12px" fontWeight={500} mr="4px">
              {aiSuggestionReason}
            </Text>
            <Icon as={MdClose} color="#0C894E" />
            <Icon as={MdCheck} color="#0C894E" />
          </Box>
        )}
      </Flex>
      <Flex>
        <Box position="relative" ml="23px" mr="-2px" mb="12px">
          <Box
            position="absolute"
            bottom="3px"
            left="0"
            h="calc(100% - 11px)"
            borderStyle="solid"
            borderWidth="0 0 2px 2px"
            borderRadius="8px 8px 0px 4px"
            borderColor={color}
          />
          <Box
            position="absolute"
            bottom="0"
            left="3px"
            w="5px"
            borderStyle="solid"
            borderWidth="0 0 2px 2px"
            borderRadius="0px 8px 8px 4px"
            borderColor={color}
          />
          <Box
            position="absolute"
            bottom="0"
            left="0"
            w="4px"
            h="4px"
            borderStyle="solid"
            borderWidth="0 0 2px 2px"
            borderRadius="0px 0px 0px 8px"
            borderColor={color}
          />
        </Box>

        <Box mt="8px" w="calc(100% - 21px)">
          {pages.map((p, idx) => {
            const isTabAboveSelected = idx > 0 ? pages[idx - 1].id in selectedPages : false
            const isTabBelowSelected =
              idx < pages.length - 1 ? pages[idx + 1].id in selectedPages : false

            return (
              <PutasideTabController
                key={p.id}
                id={p.id}
                page={p}
                onDeleteTablistPage={onDeleteTablistPage}
                shouldShowDeleteIcon={true}
                queryValue={''}
                index={idx}
                numTotalResults={numTotalResults}
                areaName={TABLIST_AREA_NAME_ENUM.RecentlySaved}
                spacingVariant={PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM.SAVED_FOR_LATER}
                showAiSuggestion={!showAiSuggestion && !!p.ai_suggestion}
                onOpenMoveMenu={onOpenPageMoveMenu}
                isHighlighted={highlightedPageIds && highlightedPageIds.includes(p.id)}
                isSelected={p.id in selectedPages}
                isTabAboveSelected={isTabAboveSelected}
                isTabBelowSelected={isTabBelowSelected}
                onSelected={onPageSelected}
                removeSelection={removePageSelection}
              />
            )
          })}
        </Box>
      </Flex>
    </Box>
  )
}

export default PutasideTabGroup
