export const {
  CURRENT_ENV,
  EXTENSION_VERSION,
  MINIMUM_EXTENSION_VERSION,
  EXTENSION_ID,
  OAUTH_WEB_CLIENT_ID,
  WEBAPP_BASE_URL,
  API_SERVER_URL,
  ENABLE_POSTHOG,
  POSTHOG_HOST,
  POSTHOG_KEY,
  ENABLE_SENTRY,
  SENTRY_DSN,
  ENABLE_META_PIXEL,
  META_PIXEL_ID,
  ENABLE_GOOGLE_ANALYTICS,
  GOOGLE_ANALYTICS_ID,
  ENABLE_CONSOLE_LOG,
  STRIPE_PUBLISHABLE_KEY,
  PUTAWAY_ALARM_DELAY_IN_MIN,
  PUTAWAY_ALARM_PERIOD_IN_MIN,
} = __ENV_VAR__

export const EXTENSION_NEW_TAB_PAGE_URL = `chrome-extension://${EXTENSION_ID}/newtab.html`
export const CHROME_NEW_TAB_PAGE_URL = 'chrome://newtab/'

export const TOLERATED_TAB_URLS = [
  `${WEBAPP_BASE_URL}/projects`,
  `${WEBAPP_BASE_URL}/folders`,
  `${WEBAPP_BASE_URL}/auth/handler`,
]
export const CHROME_STORE_URL = `https://chrome.google.com/webstore/detail/${EXTENSION_ID}`

export const FEEDBACK_FORM_URL = 'https://forms.gle/qbX7dTaxeWCZzPYp6'
export const HOW_TO_URL =
  'https://docs.skipper.co/Skipper-Getting-Started-Guide-10a5d2243c8a8027b110cf3a55a66782'
export const UNINSTALL_FEEDBACK_FORM_URL = 'https://forms.gle/ssyFK7ZHvTog8iGT8'
export const APP_NAME = 'Skipper'
export const APP_LOGO_WORDMARK_URL = '/logo-wordmark-skipper.png'
export const INSTANT_SAVE_WINDOWS_IMG_URL = '/instant-save-windows-skipper.png'
export const INSTANT_SAVE_MAC_IMG_URL = '/instant-save-mac-skipper.png'
export const SUPPORT_EMAIL = 'hello@skipper.co'
export const AUTH_REDIRECT_URL = `${WEBAPP_BASE_URL}/auth/handler`
export const LANDING_PAGE_URL = 'https://skipper.co'

export const GIT_COMMIT_HASH = __COMMIT_HASH__
