import React, { FC, useCallback, useEffect, useRef } from 'react'
import { useDrag } from 'react-dnd'
import PutasideTabView from './PutasideTabView'
import {
  DND_ITEM_ENUM,
  DnDItemTablistPagePayload,
  DnDItemTablistPagesPayload,
} from '../../../models/dnd.types'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../../models/tablist_pages.types'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { useUserContext } from '../../../contexts/UserContext'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { TABLIST_AREA_NAME_ENUM } from './PutasideTabList'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { setIsTablistPageDragging } from '../../../redux/dndSlice'
import { shouldOpenInNewTab } from '../../../utils/utils'
import { MoveTablistPageInfoType } from '../moveItemMenu/moveItemMenu.types'
import webappStore from '../../../webapp/redux/webappStore'
import { TablistPageTypeWithOrder } from '../../../redux/selectedPagesSlice'
import { getEmptyImage } from 'react-dnd-html5-backend'

interface PropTypes {
  id: string
  page: TablistPageType
  queryValue: string
  index: number
  numTotalResults: number
  areaName: TABLIST_AREA_NAME_ENUM
  shouldDisplayLocation?: boolean
  shouldShowPinIconForCheckbox?: boolean
  onDeleteTablistPage?: (params: { page: TablistPageType; index: number }) => Promise<void>
  onDeleteOpenTab?: (params: { id: string }) => Promise<void>
  onDeleteSmartSessionPage?: (params: { page: TablistPageType }) => Promise<void>
  onSaveOpenTab?: (params: { page: TablistPageType }) => Promise<void>
  onOpenMoveMenu?: (params: { pageInfo: MoveTablistPageInfoType }) => void
  onSelected?: (params: {
    clickedItemId: string
    isShiftKey: boolean
    isCtrlOrCmdKey: boolean
    isCurrentlySelected: boolean
    extraAnalyticsProps?: Record<string, unknown>
  }) => void
  removeSelection?: (params: { pageId: string }) => void
  isSelected?: boolean
  isTabAboveSelected?: boolean
  isTabBelowSelected?: boolean
  isCheckboxShown?: boolean
  isFocused?: boolean
  isAppFiltered?: boolean
  isToReadFiltered?: boolean
  isManualFiltered?: boolean
  showOpenTabIcon?: boolean
  titleHighlights?: [number, number][]
  showTimeString?: boolean
  showActionIcons?: boolean
  isSelectionDisabled?: boolean
  isHoverDisabled?: boolean
  isTitleClickDisabled?: boolean
  isDraggingDisabled?: boolean
  isDragIconPlaceholderHidden?: boolean
  extraAnalyticsProps?: Record<string, unknown>
  isHighlighted?: boolean
  spacingVariant?: 'default' | 'saved_for_later'
}

const PutasideTabController: FC<PropTypes> = (props) => {
  const {
    id,
    page,
    onDeleteTablistPage,
    onDeleteOpenTab,
    onDeleteSmartSessionPage,
    onSaveOpenTab,
    onOpenMoveMenu,
    onSelected,
    removeSelection,
    titleHighlights,
    queryValue,
    index,
    numTotalResults,
    isTabAboveSelected = false,
    isTabBelowSelected = false,
    isSelected = false,
    shouldDisplayLocation = false,
    isCheckboxShown = false,
    isFocused = false,
    isAppFiltered = false,
    isToReadFiltered = false,
    isManualFiltered = false,
    showOpenTabIcon = false,
    isSelectionDisabled = false,
    isHoverDisabled = false,
    isTitleClickDisabled = false,
    isDraggingDisabled = false,
    showTimeString = true,
    showActionIcons = true,
    shouldShowPinIconForCheckbox = false,
    isDragIconPlaceholderHidden = false,
    isHighlighted = false,
    spacingVariant = 'default',
    areaName,
    extraAnalyticsProps = {},
  } = props
  const { captureAnalytics } = useUserContext()
  const { projectConfig } = useFeatureFlagContext()
  const dispatch = useReduxDispatch()
  const isTablistPageDragging = useReduxSelector((state) => state.dnd.isTablistPageDragging)
  const tabContainerRef = useRef<HTMLDivElement | null>(null)
  const componentName = `tablist_${areaName}`

  useEffect(() => {
    if (isSelected && isTablistPageDragging) {
      console.log('TABLIST PAGE DRAGGING')
    }
  }, [isTablistPageDragging, isSelected])

  useEffect(() => {
    if (isFocused && tabContainerRef.current) {
      tabContainerRef.current.scrollIntoView({ block: 'nearest' })
    }
  }, [isFocused])

  const handleClickSelect = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (isSelectionDisabled || !onSelected) {
        return
      }

      captureAnalytics(`${componentName}:tab_selection_toggle`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        isAppFiltered,
        isToReadFiltered,
        isManualFiltered,
        index,
        numTotalResults,
        isSelected: !isSelected,
        ...extraAnalyticsProps,
      })

      onSelected({
        clickedItemId: id,
        isShiftKey: event.shiftKey,
        isCtrlOrCmdKey: event.ctrlKey || event.metaKey,
        isCurrentlySelected: isSelected,
      })
    },
    [
      extraAnalyticsProps,
      isSelectionDisabled,
      componentName,
      captureAnalytics,
      page,
      queryValue,
      isAppFiltered,
      isToReadFiltered,
      isManualFiltered,
      index,
      numTotalResults,
      isSelected,
      onSelected,
      id,
    ],
  )

  const handleClickTitle = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()

      captureAnalytics(`${componentName}:tab_title_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        isAppFiltered,
        isToReadFiltered,
        isManualFiltered,
        index,
        numTotalResults,
        ...extraAnalyticsProps,
      })

      if (page.is_open) {
        sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SWITCH_TO_TAB, {
          tabId: parseInt(page.id),
          windowId: page.window_id,
        })
      } else {
        sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
          urls: [page.url],
          shouldActivate: !shouldOpenInNewTab(event),
        })
      }
    },
    [
      extraAnalyticsProps,
      componentName,
      index,
      isAppFiltered,
      isToReadFiltered,
      isManualFiltered,
      numTotalResults,
      page,
      captureAnalytics,
      queryValue,
    ],
  )

  const handleClickDeleteIcon = useCallback(
    async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (!page.entity_type) {
        return
      }

      captureAnalytics(`${componentName}:delete_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        isAppFiltered,
        isToReadFiltered,
        isManualFiltered,
        index,
        numTotalResults,
        ...extraAnalyticsProps,
      })

      if (page.entity_type === TABLIST_PAGE_ENUM.OPEN && !!onDeleteOpenTab) {
        await onDeleteOpenTab({ id })
      } else if (
        page.entity_type === TABLIST_PAGE_ENUM.SMART_SESSION &&
        !!onDeleteSmartSessionPage
      ) {
        await onDeleteSmartSessionPage({ page })
      } else if (page.entity_type === TABLIST_PAGE_ENUM.RECENTLY_USED && !!onDeleteTablistPage) {
        await onDeleteTablistPage({ page, index })
      } else {
        return
      }

      isSelected && removeSelection && removeSelection({ pageId: page.id })
    },
    [
      page,
      captureAnalytics,
      componentName,
      queryValue,
      isAppFiltered,
      isToReadFiltered,
      isManualFiltered,
      index,
      numTotalResults,
      extraAnalyticsProps,
      onDeleteOpenTab,
      onDeleteSmartSessionPage,
      onDeleteTablistPage,
      isSelected,
      removeSelection,
      id,
    ],
  )

  const handleClickSaveIcon = useCallback(
    async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (!page.entity_type || page.entity_type !== TABLIST_PAGE_ENUM.OPEN || !onSaveOpenTab) {
        return
      }

      captureAnalytics(`${componentName}:save_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        index,
        numTotalResults,
        ...extraAnalyticsProps,
      })

      await onSaveOpenTab({ page })
      isSelected && removeSelection && removeSelection({ pageId: page.id })
    },
    [
      page,
      onSaveOpenTab,
      captureAnalytics,
      componentName,
      queryValue,
      index,
      numTotalResults,
      extraAnalyticsProps,
      isSelected,
      removeSelection,
    ],
  )

  const handleClickMoveIcon = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (
        !page.entity_type ||
        page.entity_type !== TABLIST_PAGE_ENUM.RECENTLY_USED ||
        !onOpenMoveMenu
      ) {
        return
      }

      captureAnalytics(`${componentName}:move_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        index,
        numTotalResults,

        ...extraAnalyticsProps,
      })

      onOpenMoveMenu({ pageInfo: { type: 'TABLIST_PAGE', page, index } })
      isSelected && removeSelection && removeSelection({ pageId: page.id })
    },
    [
      page,
      onOpenMoveMenu,
      captureAnalytics,
      componentName,
      queryValue,
      index,
      numTotalResults,
      extraAnalyticsProps,
      isSelected,
      removeSelection,
    ],
  )

  const [{ isDraggingThis }, connectDragSource, connectDragPreview] = useDrag(
    () => ({
      type: DND_ITEM_ENUM.TABLIST_PAGES,
      collect: (monitor) => ({
        isDraggingThis: monitor.isDragging(),
      }),
      canDrag: () => !isDraggingDisabled,
      end: () => dispatch(setIsTablistPageDragging(false)),
      item: () => {
        dispatch(setIsTablistPageDragging(true))

        const payloads: DnDItemTablistPagePayload[] = []

        if (isSelected) {
          const selectedPages: TablistPageTypeWithOrder[] = []
          if (areaName === TABLIST_AREA_NAME_ENUM.RecentlySaved) {
            selectedPages.push(
              ...Object.values(webappStore.getState().selectedPages.selectedSavedForLaterPages),
            )
          } else if (areaName === TABLIST_AREA_NAME_ENUM.SmartSessions) {
            selectedPages.push(
              ...Object.values(webappStore.getState().selectedPages.selectedSmartSessionPages),
            )
          }

          payloads.push(
            ...selectedPages.map(
              (p): DnDItemTablistPagePayload => ({
                type: DND_ITEM_ENUM.TABLIST_PAGE,
                page: p,
                index: p.order,
              }),
            ),
          )
        } else {
          payloads.push({
            type: DND_ITEM_ENUM.TABLIST_PAGE,
            page,
            index,
          })
        }

        payloads.sort((a, b) => a.index - b.index)

        const payload: DnDItemTablistPagesPayload = {
          type: DND_ITEM_ENUM.TABLIST_PAGES,
          payloads,
        }

        return payload
      },
    }),
    [id, page, dispatch, isDraggingDisabled, index, isSelected],
  )

  useEffect(() => {
    connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [connectDragPreview])

  return (
    <PutasideTabView
      location={page.location}
      entityType={page.entity_type}
      title={
        page.custom_title && page.custom_title.length > 0
          ? page.custom_title
          : page.title ?? 'Untitled Page'
      }
      favIconUrl={page.favicon_url}
      lastAccessDateTime={page.last_access_datetime_utc_iso}
      showTimeString={showTimeString}
      titleHighlights={titleHighlights}
      isTitleClickDisabled={isTitleClickDisabled}
      showOpenTabIcon={showOpenTabIcon}
      isPinnedTab={page.is_pinned}
      handleClickCheckbox={handleClickSelect}
      handleClickFavIcon={handleClickTitle}
      handleClickDragIcon={handleClickSelect}
      handleClickDeleteIcon={
        (!!onDeleteOpenTab && page.entity_type === TABLIST_PAGE_ENUM.OPEN) ||
        (!!onDeleteTablistPage && page.entity_type === TABLIST_PAGE_ENUM.RECENTLY_USED) ||
        (!!onDeleteSmartSessionPage && page.entity_type === TABLIST_PAGE_ENUM.SMART_SESSION)
          ? handleClickDeleteIcon
          : undefined
      }
      handleClickSaveIcon={
        page.entity_type === TABLIST_PAGE_ENUM.OPEN && onSaveOpenTab
          ? handleClickSaveIcon
          : undefined
      }
      handleClickTitle={handleClickTitle}
      handleClickBody={handleClickSelect}
      isTabAboveSelected={isTabAboveSelected}
      isTabBelowSelected={isTabBelowSelected}
      isSelected={isSelected}
      isFocused={isFocused}
      isSelectionDisabled={isSelectionDisabled}
      isHoverDisabled={isHoverDisabled}
      isSelectionDisabledTooltipLabel={`You cannot select more than ${projectConfig.maxNumProjectsPages} pages`}
      isCheckboxShown={isCheckboxShown}
      isDragIconPlaceholderHidden={isDragIconPlaceholderHidden}
      isDraggingThis={isDraggingThis || (isSelected && isTablistPageDragging)}
      isDraggingDisabled={isDraggingDisabled}
      isHighlighted={isHighlighted}
      connectDnD={!isDraggingDisabled ? connectDragSource : undefined}
      tabContainerRef={tabContainerRef}
      moveIconTooltip={'Move'}
      handleClickMoveIcon={
        page.entity_type === TABLIST_PAGE_ENUM.RECENTLY_USED && onOpenMoveMenu
          ? handleClickMoveIcon
          : undefined
      }
      titleToolTip={
        page.entity_type === TABLIST_PAGE_ENUM.OPEN ? (
          'Go to Tab'
        ) : page.custom_title ? (
          <span style={{ fontSize: '12px' }}>
            Original title: {page.title}
            <br /> <br />
            URL: {page.url}
          </span>
        ) : (
          page.url
        )
      }
      deleteIconToolTip={
        page.entity_type === TABLIST_PAGE_ENUM.OPEN
          ? 'Close tab'
          : page.entity_type === TABLIST_PAGE_ENUM.RECENTLY_USED ||
              page.entity_type === TABLIST_PAGE_ENUM.SMART_SESSION
            ? 'Remove tab'
            : undefined
      }
      saveIconTooltip={onSaveOpenTab ? 'Save & close' : undefined}
      showActionIcons={showActionIcons}
      shouldShowPinIconForCheckbox={shouldShowPinIconForCheckbox}
      shouldDisplayLocation={shouldDisplayLocation}
      spacingVariant={spacingVariant}
    />
  )
}

export default React.memo(PutasideTabController)
