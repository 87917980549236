import React, { FC, useEffect, useMemo, useState, useCallback, useRef } from 'react'
import {
  Text,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  Spinner,
  ButtonGroup,
  Checkbox,
  Tooltip,
  Icon,
  UseToastOptions,
  ToastId,
  useToast,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../../contexts/UserContext'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { getStaleTabRecords, mapTabRecordToTablistPage } from '../../../utils/tabUtils'
import {
  useDeleteOpenTabsMutation,
  useGetAllTabRecordsQuery,
} from '../../../webapp/redux/extension'
import {
  EXTENSION_SETTINGS_KEYS,
  ExtensionSettingsType,
} from '../../../extension/models/extensionStorage.types'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../extension/pages/popup/utils/messaging'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { MdInfoOutline } from 'react-icons/md'
import { useAppendTablistPagesMutation } from '../../../redux/services/skeema/tablist_pages.endpoints'
import { GLOBAL_WINDOW_MESSAGE_ENUM } from '../../../utils/utils'
import { sendGlobalWindowMessage } from '../../../utils/utils'
import { useReduxDispatch } from '../../../redux/baseStore'
import { setHighlightedPageIds } from '../../../redux/tablistSlice'
import NotificationToast from '../../../components/NotificationToast'
import { PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM } from '../PutasideTabList/PutasideTabView'

export interface DeselectedTabsType {
  [id: string]: TablistPageType
}

const TOAST_DURATION_MS = 3000
const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'top',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

const GuidedAutoSaveModal: FC = () => {
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()

  const didLoadSettingsRef = useRef<boolean>(false)

  const toast = useToast(USE_TOAST_OPTIONS)
  const toastIdRef = useRef<ToastId | undefined>(undefined)

  const [isAutoSaveCheckboxChecked, setIsAutoSaveCheckboxChecked] = useState<boolean>(false)
  const [deselectedTabs, setDeselectedTabs] = useState<DeselectedTabsType | undefined>(undefined)
  const [lastSelectedItem, setLastSelectedItem] = useState<string | undefined>(undefined)

  const [extensionSettings, setExtensionSettings] = useState<ExtensionSettingsType | null>(null)
  const overwhelmThreshold = extensionSettings
    ? extensionSettings[EXTENSION_SETTINGS_KEYS.OVERWHELM_THRESHOLD]
    : undefined
  const stalenessThresholdAfterOverwhelm = extensionSettings
    ? extensionSettings[EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM]
    : undefined

  const { data: openTabRecords } = useGetAllTabRecordsQuery(undefined, { pollingInterval: 1000 })

  const isLoading = !openTabRecords || !extensionSettings

  const tabsToClose = useMemo(() => {
    if (
      !openTabRecords ||
      overwhelmThreshold === undefined ||
      stalenessThresholdAfterOverwhelm === undefined
    ) {
      return undefined
    }

    const sortTabsFunc = (a: TablistPageType, b: TablistPageType) => {
      if (a.is_pinned && !b.is_pinned) {
        return -1
      }
      if (!a.is_pinned && b.is_pinned) {
        return 1
      }
      return b.last_access_timestamp_ms - a.last_access_timestamp_ms
    }

    const windowIds = new Set(openTabRecords.map((t) => t.windowId))

    const tabsToClose = Array.from(windowIds)
      .map((id) => {
        return getStaleTabRecords({
          tabRecords: openTabRecords,
          shouldHaveExperimentalPutawayV2: false,
          windowId: id,
          tabOverwhelmThreshold: overwhelmThreshold,
          stalenessThresholdBeforeOverwhelm: 0,
          stalenessThresholdAfterOverwhelm,
        })
      })
      .reduce((acc, val) => acc.concat(val), [])
      .map(mapTabRecordToTablistPage)
      .sort(sortTabsFunc)

    return tabsToClose
  }, [openTabRecords, overwhelmThreshold, stalenessThresholdAfterOverwhelm])

  const handleTabSelection = useCallback(
    (params: {
      clickedItemId: string
      isShiftKey: boolean
      isCtrlOrCmdKey: boolean
      isCurrentlySelected: boolean
    }): void => {
      const { clickedItemId, isShiftKey, isCurrentlySelected } = params
      if (!tabsToClose) {
        return
      }

      if (isCurrentlySelected) {
        const tabIdx = tabsToClose.findIndex((t) => t.id === clickedItemId)
        const tab = tabIdx > -1 ? tabsToClose[tabIdx] : undefined
        if (tab === undefined || !tab.id) {
          return
        }

        const addedTabs: DeselectedTabsType = {}

        if (isShiftKey && lastSelectedItem !== undefined) {
          const lastTabIdx = tabsToClose.findIndex((t) => t.id === lastSelectedItem)
          if (lastTabIdx > -1) {
            const start = Math.min(tabIdx, lastTabIdx)
            const end = Math.max(tabIdx, lastTabIdx)
            for (let i = start; i <= end; i++) {
              const curr = tabsToClose[i]
              if (curr.id) {
                addedTabs[curr.id] = curr
              }
            }
          }
        } else {
          addedTabs[tab.id] = tab
        }

        setDeselectedTabs((curr) => {
          const newObject = { ...curr, ...addedTabs }
          return newObject
        })
      } else {
        const tabIdsToDeselect: Set<string> = new Set([clickedItemId])

        if (isShiftKey && lastSelectedItem !== undefined) {
          const tabIdx = tabsToClose.findIndex((t) => t.id === clickedItemId)
          const lastTabIdx = tabsToClose.findIndex((t) => t.id === lastSelectedItem)
          if (tabIdx > -1 && lastTabIdx > -1) {
            const start = Math.min(tabIdx, lastTabIdx)
            const end = Math.max(tabIdx, lastTabIdx)
            for (let i = start; i <= end; i++) {
              const curr = tabsToClose[i]
              if (curr.id) {
                tabIdsToDeselect.add(curr.id)
              }
            }
          }
        }

        setDeselectedTabs((curr) => {
          const newObject = { ...curr }
          tabIdsToDeselect.forEach((t) => {
            delete newObject[t]
          })
          return newObject
        })
      }

      captureAnalytics('guided_autosave_modal:select_tab_click', {
        selected: !isCurrentlySelected,
        tabId: clickedItemId,
        isShiftKey,
        title: tabsToClose.find((t) => t.id === clickedItemId)?.title,
      })

      setLastSelectedItem(clickedItemId)
    },
    [captureAnalytics, lastSelectedItem, tabsToClose],
  )

  const dismissModal = useCallback(() => {
    navigate(window.location.pathname)
  }, [navigate])

  const handleMasterCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      captureAnalytics('guided_autosave_modal:master_checkbox_change', {
        isChecked: e.target.checked,
        tabsToClose,
        tabsToCloseLength: tabsToClose?.length ?? 0,
        deselectedTabs: Object.values(deselectedTabs ?? {}),
        deselectedTabsLength: Object.keys(deselectedTabs ?? {}).length,
      })

      if (e.target.checked) {
        setDeselectedTabs({})
      } else {
        setDeselectedTabs(
          tabsToClose?.reduce((acc, t) => {
            acc[t.id] = t
            return acc
          }, {} as DeselectedTabsType) ?? {},
        )
      }
    },
    [captureAnalytics, deselectedTabs, tabsToClose],
  )

  const onCancel = () => {
    captureAnalytics('guided_autosave_modal:dismiss_button_click', {
      tabsToClose,
      deselectedTabs: Object.values(deselectedTabs ?? {}),
      numTabsToClose: tabsToClose?.length ?? 0,
      numDeselectedTabs: Object.keys(deselectedTabs ?? {}).length,
      isAutoSaveCheckboxChecked,
    })

    dismissModal()
  }

  const handleAutoSaveCheckboxChange = (
    e: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
    e.preventDefault()
    captureAnalytics('guided_autosave_modal:automatic_save_checkbox_change', {
      isChecked: !isAutoSaveCheckboxChecked,
    })
    setIsAutoSaveCheckboxChecked(!isAutoSaveCheckboxChecked)
  }

  const [appendTablistPages] = useAppendTablistPagesMutation()
  const [deleteOpenTabs] = useDeleteOpenTabsMutation()

  const onSaveAndClose = async () => {
    navigate('/')
    sendGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.SWITCH_TO_SAVED_TABS_VIEW)

    const actualTabsToClose = tabsToClose?.filter((t) => !(t.id in (deselectedTabs ?? {})))

    if (actualTabsToClose && actualTabsToClose.length > 0) {
      const result = await appendTablistPages({ pages: actualTabsToClose, isManual: true })
        .unwrap()
        .then((res) => {
          if (res.ok) {
            deleteOpenTabs({ ids: actualTabsToClose.map((t) => t.id) })
          }
          return res
        })
        .catch(() => {
          console.error('ERROR: Guided auto save and close failed at button click')
        })

      if (result && result.ok && 'data' in result.details) {
        const pageIds = result.details.data.map((p) => p.id)
        dispatch(setHighlightedPageIds(pageIds))

        if (toastIdRef.current) {
          toast.close(toastIdRef.current)
          toastIdRef.current = undefined
        }
        toastIdRef.current = toast({
          duration: TOAST_DURATION_MS,
          containerStyle: { minWidth: 'auto' },
          render: () => (
            <NotificationToast
              message={`${pageIds.length} tabs have been saved to 'All saved tabs'`}
            />
          ),
        })
      }
    }

    if (isAutoSaveCheckboxChecked) {
      await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
        settings: {
          isGuidedAutoSaveEnabled: false,
        },
      })
    }

    captureAnalytics('guided_autosave_modal:save_button_click', {
      tabsToClose,
      actualTabsToClose,
      isAutoSaveCheckboxChecked,
      deselectedTabs: Object.values(deselectedTabs ?? {}),
      numTabsToClose: tabsToClose?.length ?? 0,
      numActualTabsToClose: actualTabsToClose?.length ?? 0,
      numDeselectedTabs: Object.keys(deselectedTabs ?? {}).length,
    })

    dismissModal()
  }

  useEffect(() => {
    //Remove all deselected tabs not in tabsToClose
    setDeselectedTabs((curr) => {
      if (!curr) {
        return curr
      }

      const tabIdsToRemove = new Set<string>()
      Object.keys(curr).forEach((t) => {
        if (!tabsToClose?.some((t2) => t2.id === t)) {
          tabIdsToRemove.add(t)
        }
      })

      if (tabIdsToRemove.size === 0) {
        return curr
      }

      const newObject = { ...curr }
      tabIdsToRemove.forEach((t) => {
        delete newObject[t]
      })
      return newObject
    })
  }, [tabsToClose])

  useEffect(() => {
    if (didLoadSettingsRef.current) {
      return
    }

    didLoadSettingsRef.current = true

    sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_EXTENSION_SETTINGS)
      .then((response) => {
        const settings = response as ExtensionSettingsType
        setExtensionSettings(settings)
        setIsAutoSaveCheckboxChecked(!settings[EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED])
      })
      .catch((e) => {
        console.error('Error fetching settings', e)
        dismissModal()
      })
  }, [dismissModal])

  useEffect(() => {
    const handleVisibilityChange = () => {
      const isHidden = window.document.hidden
      captureAnalytics('guided_autosave_modal:visibility_change', {
        isHidden,
      })
    }
    window.document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      window.document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [captureAnalytics])

  const isBrowserAlreadyClean =
    (openTabRecords && openTabRecords.length === 0) || (tabsToClose && tabsToClose.length === 0)

  const isMasterCheckboxChecked =
    tabsToClose && tabsToClose.length > 0 && Object.keys(deselectedTabs ?? {}).length === 0
  const isMasterCheckboxIndeterminate =
    tabsToClose &&
    tabsToClose.length > 0 &&
    Object.keys(deselectedTabs ?? {}).length > 0 &&
    Object.keys(deselectedTabs ?? {}).length < tabsToClose.length

  const stalenessThresholdHours = Math.floor((stalenessThresholdAfterOverwhelm ?? 0) / 3600000)

  return (
    <Modal
      isCentered
      size="2xl"
      isOpen={true}
      onClose={onCancel}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur={'4px'} />
      <ModalContent borderRadius={'16px'}>
        <ModalHeader p="24px 24px 0 30px">
          {isBrowserAlreadyClean
            ? `Skipper hasn't found any tabs you haven't used in over ${stalenessThresholdHours} ${
                stalenessThresholdHours === 1 ? 'hour' : 'hours'
              }.`
            : `Skipper found ${tabsToClose?.length ?? 0} ${
                tabsToClose?.length === 1 ? 'tab' : 'tabs'
              } you haven't used in over ${stalenessThresholdHours} ${
                stalenessThresholdHours === 1 ? 'hour' : 'hours'
              }.`}
          <br />
          {isBrowserAlreadyClean ? 'Keep up the good work!' : 'Save and close for better focus?'}
        </ModalHeader>
        <ModalBody p="16px 24px 0px 24px">
          {isBrowserAlreadyClean && (
            <Box className="flex-center" w="100%">
              <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
                {`Wow! Your browser is already clean. We'll help you keep it that way.`}
              </Text>
            </Box>
          )}
          {(!tabsToClose || tabsToClose.length > 0) && (
            <Box>
              <Text
                mb="32px"
                ml="6px"
                fontSize="14px"
                fontWeight={500}
                color="#a7a7a7"
                lineHeight="22px"
              >
                {`Selected tabs below will be saved in All Saved Tabs.`}
              </Text>

              {isLoading && (
                <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
                  <Spinner color="blue.500" size="lg" speed="1s" />
                </div>
              )}

              {tabsToClose && tabsToClose.length > 0 && (
                <Box ml="1px">
                  <Flex paddingBottom="6px" ml="5px" borderBottom="1px solid #d5d5d5">
                    <Checkbox
                      colorScheme="black"
                      isChecked={isMasterCheckboxChecked}
                      isIndeterminate={isMasterCheckboxIndeterminate}
                      onChange={handleMasterCheckboxChange}
                    />
                    <Text ml="14px" fontSize="12px" fontWeight={500} color="#a7a7a7">
                      Tab title
                    </Text>
                    <Text
                      position="absolute"
                      right="112px"
                      fontSize="12px"
                      fontWeight={500}
                      color="#a7a7a7"
                    >
                      Last used
                    </Text>
                  </Flex>
                  <Box className="scrollbars-always-visible" overflowY="auto" maxHeight="300px">
                    {tabsToClose.map((t, idx) => {
                      return (
                        <PutasideTabController
                          key={t.id}
                          id={t.id}
                          page={t}
                          showTimeString={true}
                          queryValue={''}
                          index={idx}
                          numTotalResults={tabsToClose.length}
                          areaName={TABLIST_AREA_NAME_ENUM.Open}
                          isTitleClickDisabled={true}
                          isDraggingDisabled={true}
                          isHoverDisabled={true}
                          isDragIconPlaceholderHidden={true}
                          isSelected={!(t.id in (deselectedTabs ?? {}))}
                          onSelected={handleTabSelection}
                          isCheckboxShown={true}
                          spacingVariant={PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM.SAVED_FOR_LATER}
                        />
                      )
                    })}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </ModalBody>

        <ModalFooter
          p="32px 24px 24px 24px"
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Flex alignItems="center" mb="16px" onClick={handleAutoSaveCheckboxChange}>
            <Checkbox
              colorScheme="black"
              isChecked={isAutoSaveCheckboxChecked}
              onChange={handleAutoSaveCheckboxChange}
            />
            <Text ml={1} fontSize="12px" fontWeight={500} color="#585858" whiteSpace={'pre'}>
              {'Switch to '}
            </Text>
            <Tooltip
              placement="top"
              label={`Skipper will save and close inactive tabs automatically, without asking for your review.`}
              shouldWrapChildren={true}
            >
              <Flex alignItems="center">
                <Text fontSize="12px" fontWeight={500} color="#585858" textDecoration="underline">
                  {'Automatic Save mode'}
                </Text>
                <Icon as={MdInfoOutline} boxSize={4} ml={1} color="#585858" />
              </Flex>
            </Tooltip>
          </Flex>
          <ButtonGroup>
            <Button
              height={'36px'}
              size="md"
              variant="outline"
              borderRadius="100px"
              paddingRight="24px"
              paddingLeft="24px"
              color={'black'}
              border="1px solid #A7A7A7"
              fontWeight={500}
              _hover={{
                backgroundColor: '#EBEBEB',
              }}
              onClick={onCancel}
            >
              Remind me later
            </Button>
            <Tooltip
              label="There are no tabs to save and close"
              placement="top"
              isDisabled={
                tabsToClose &&
                tabsToClose.length > 0 &&
                (isMasterCheckboxChecked || isMasterCheckboxIndeterminate)
              }
              shouldWrapChildren
            >
              <Button
                ml="8px"
                height={'36px'}
                size="md"
                variant="solid"
                borderRadius="100px"
                paddingRight="24px"
                paddingLeft="24px"
                backgroundColor={'black'}
                color={'white'}
                fontWeight={500}
                _hover={{
                  backgroundColor: '#585858',
                }}
                _disabled={{
                  backgroundColor: '#D5D5D5',
                  color: '#ffffff',
                }}
                onClick={onSaveAndClose}
                isDisabled={
                  !tabsToClose ||
                  tabsToClose.length === 0 ||
                  (!isMasterCheckboxChecked && !isMasterCheckboxIndeterminate)
                }
                autoFocus
              >
                Save & close
              </Button>
            </Tooltip>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GuidedAutoSaveModal
