import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Flex, Text, Button, Spinner, Box } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { FiDownload } from 'react-icons/fi'
import { TablistPageTopicGroupType, TablistPageType } from '../../../models/tablist_pages.types'
import {
  useDeleteTablistPageMutation,
  useGetAllSavedTabsQuery,
  useLazyGetAllSavedTabsQuery,
} from '../../../redux/services/skeema/tablist_pages.endpoints'
import {
  loadOlderSavedPages,
  selectHighlightedPageIds,
  setHighlightedPageIds,
  updateMostRecentSavedPages,
} from '../../../redux/tablistSlice'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { useUserContext } from '../../../contexts/UserContext'
import { useLocation, useNavigate } from 'react-router-dom'
import SavedPagesLimitModal from '../PutasideTabList/SavedPagesLimitModal'
import { IoFilter } from 'react-icons/io5'
import ToggleButton from '../../../components/ToggleButton'
import { MoveItemPageInfoType, MoveTablistPageInfoType } from '../moveItemMenu/moveItemMenu.types'
import MoveItemModal from '../moveItemMenu/MoveItemModal'
import { APP_NAME } from '../../../constants'
import {
  HIGHLIGHT_ANIMATION_DURATION_MS,
  PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM,
} from './PutasideTabView'
import {
  handleSavedForLaterPageSelection,
  removeSelectedSavedForLaterPages,
  selectSelectedSavedForLaterPages,
} from '../../../redux/selectedPagesSlice'
import MultiSelectToolbar from '../multiSelectToolbar/MultiSelectToolbar'
import ComingSoonModal from './ComingSoonModal'
import PutasideTopicGroup from './PutasideTopicGroup'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { CHROME_STORAGE_LOCAL_KEYS } from '../../../extension/models/extensionStorage.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import {
  selectIsFolderCreationModeActive,
  selectPendingFolderCreationPages,
} from '../../../redux/projectsSlice'

const TabSweepZeroStateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px 16px;
  width: 100%;

  & > * {
    font-size: 14px;
    color: #a7a7a7;
    white-space: pre;
  }
`

export interface TablistFiltersType {
  isManualFilterActive: boolean
  isMostVisitedSortActive: boolean
  isGroupByTopicsActive: boolean
}

const SavedForLaterList: FC = () => {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { captureAnalytics } = useUserContext()

  const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState<boolean>(false)
  const [isSavedPagesLimitModalOpen, setIsSavedPagesLimitModalOpen] = useState<boolean>(false)
  const [tablistFilters, setTablistFilters] = useState<TablistFiltersType>({
    isManualFilterActive: false,
    isMostVisitedSortActive: false,
    isGroupByTopicsActive: false,
  })
  const [groups, setGroups] = useState<
    { [groupId: string]: TablistPageTopicGroupType } | undefined
  >(undefined)

  const isFolderCreationModeActive = useReduxSelector(selectIsFolderCreationModeActive)
  const pendingFolderCreationPages = useReduxSelector(selectPendingFolderCreationPages)

  const selectedPages = useReduxSelector(selectSelectedSavedForLaterPages)
  const isSelectionActive = Object.keys(selectedPages).length > 0

  const highlightedPageIds = useReduxSelector(selectHighlightedPageIds)
  useEffect(() => {
    if (highlightedPageIds && highlightedPageIds.length > 0) {
      setTimeout(() => {
        dispatch(setHighlightedPageIds(undefined))
      }, HIGHLIGHT_ANIMATION_DURATION_MS)
    }
  }, [dispatch, highlightedPageIds])

  const { hash } = useLocation()
  const shouldRetrieveHighlightedPages = hash === '#highlighted_tabs'
  useEffect(() => {
    if (shouldRetrieveHighlightedPages) {
      void sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_CHROME_STORAGE, {
        storageType: 'local',
      })
        .then((result) => {
          if (result) {
            const highlightedPageIds = (result as { [key: string]: string[] })[
              CHROME_STORAGE_LOCAL_KEYS.HighlightedPageIds
            ]
            if (highlightedPageIds && highlightedPageIds.length > 0) {
              dispatch(setHighlightedPageIds(highlightedPageIds))
            }
          }
        })
        .catch((e) => {
          console.error(e)
          dispatch(setHighlightedPageIds(undefined))
        })
        .finally(() => {
          navigate(window.location.pathname)
        })
    }
  }, [shouldRetrieveHighlightedPages, dispatch, navigate])

  const savedPages = useReduxSelector((state) => state.tablist.savedPages)
  const [_deleteTablistPage] = useDeleteTablistPageMutation()
  const deleteTablistPage = _deleteTablistPage as unknown as (params: {
    page: TablistPageType
    index: number
  }) => Promise<void>

  const { data: tablistPagesQueryResult } = useGetAllSavedTabsQuery({
    page: 1,
    should_include_most_visited: true,
    is_enhanced_tablist: true,
  })
  const mostRecentSavedPages = tablistPagesQueryResult?.tablist
  const mostVisitedPages = tablistPagesQueryResult?.most_visited_pages

  const filteredPages = useMemo(() => {
    let retVal: TablistPageType[] | undefined = savedPages

    if (tablistFilters.isMostVisitedSortActive) {
      retVal = mostVisitedPages
    }

    if (!tablistFilters.isManualFilterActive) {
      return retVal
    }

    return retVal?.filter((page) => {
      return page.is_manual
    })
  }, [savedPages, tablistFilters, mostVisitedPages])

  const groupedPages = useMemo((): (TablistPageType | TablistPageType[])[] | undefined => {
    if (!savedPages) {
      return savedPages
    }

    const groupArrays: { [groupId: string]: TablistPageType[] } = {}

    return savedPages.reduce(
      (acc, page) => {
        const groupId = page.group_id
        if (groupId === undefined) {
          acc.push(page)
        } else if (groupArrays[groupId]) {
          groupArrays[groupId].push(page)
        } else {
          const newGroupArray = [page]
          groupArrays[groupId] = newGroupArray
          acc.push(newGroupArray)
        }

        return acc
      },
      [] as (TablistPageType | TablistPageType[])[],
    )
  }, [savedPages])

  useEffect(() => {
    if (tablistPagesQueryResult) {
      setGroups(tablistPagesQueryResult.groups)
    }
  }, [tablistPagesQueryResult])

  useEffect(() => {
    //Syncing the most recent saved pages with the redux store
    mostRecentSavedPages && dispatch(updateMostRecentSavedPages(mostRecentSavedPages))
  }, [dispatch, mostRecentSavedPages])

  const [
    triggerGetOlderTablistPagesQuery,
    {
      data: olderTablistPagesQueryData,
      error: olderTablistPagesQueryError,
      isFetching: isMoreSavedPagesLoading,
    },
  ] = useLazyGetAllSavedTabsQuery({ refetchOnFocus: false })

  const doMoreSavedPagesExist = olderTablistPagesQueryData
    ? olderTablistPagesQueryData.next_page !== null
    : tablistPagesQueryResult?.next_page !== null

  useEffect(() => {
    //Loading the older "load more" saved pages into the redux store
    if (olderTablistPagesQueryError) {
      console.error(olderTablistPagesQueryError)
      return
    }

    if (olderTablistPagesQueryData) {
      const newGroups = olderTablistPagesQueryData.groups
      setGroups((prev) => {
        return { ...prev, ...newGroups }
      })
      dispatch(loadOlderSavedPages(olderTablistPagesQueryData.tablist))
    }
  }, [dispatch, olderTablistPagesQueryData, olderTablistPagesQueryError])

  const nextPage = olderTablistPagesQueryData?.next_page ?? tablistPagesQueryResult?.next_page
  const onClickShowMoreSavedPages = useCallback(() => {
    captureAnalytics('saved_for_later_list:show_more_saved_pages_click')

    if (!nextPage) {
      return
    }

    triggerGetOlderTablistPagesQuery({
      page: nextPage,
      should_include_most_visited: false,
    })
  }, [captureAnalytics, nextPage, triggerGetOlderTablistPagesQuery])

  const [moveSavedItemPageInfo, setMoveSavedItemPageInfo] = useState<
    MoveItemPageInfoType | undefined
  >(undefined)
  const handleOpenMoveSavedItemModal = useCallback(
    (params: { pageInfo: MoveTablistPageInfoType }) => {
      const { pageInfo } = params
      setMoveSavedItemPageInfo(pageInfo)
      captureAnalytics('saved_for_later_list:move_saved_item_modal_open', {
        ...(pageInfo ?? {}),
      })
    },
    [captureAnalytics, setMoveSavedItemPageInfo],
  )

  const handlePageSelection = useCallback(
    (params: {
      clickedItemId: string
      isShiftKey: boolean
      isCtrlOrCmdKey: boolean
      isCurrentlySelected: boolean
      extraAnalyticsProps?: Record<string, unknown>
    }): void => {
      const { clickedItemId, isShiftKey, isCtrlOrCmdKey, extraAnalyticsProps } = params

      if (!filteredPages) {
        return
      }

      dispatch(
        handleSavedForLaterPageSelection({
          pages: filteredPages,
          clickedItemId,
          isShiftKey,
          isCtrlOrCmdKey,
        }),
      )

      captureAnalytics('saved_for_later_list:page_select', {
        clickedItemId,
        numSelectedPages: Object.keys(selectedPages).length,
        isCurrentlySelected: clickedItemId in selectedPages,
        isShiftKey,
        isCtrlOrCmdKey,
        ...(extraAnalyticsProps ?? {}),
      })
    },
    [filteredPages, dispatch, captureAnalytics, selectedPages],
  )

  const handleRemovePageSelection = useCallback(
    (params: { pageId: string }) => {
      const { pageId } = params
      dispatch(removeSelectedSavedForLaterPages([pageId]))

      captureAnalytics('saved_for_later_list:page_selection_remove_side_effect', {
        pageId,
        numSelectedPages: Object.keys(selectedPages).length,
        isCurrentlySelected: pageId in selectedPages,
      })
    },
    [dispatch, captureAnalytics, selectedPages],
  )

  const handleIsManualButtonClicked = () => {
    setTablistFilters((prev) => {
      return {
        ...prev,
        isGroupByTopicsActive: !prev.isManualFilterActive ? false : prev.isGroupByTopicsActive,
        isManualFilterActive: !prev.isManualFilterActive,
      }
    })

    captureAnalytics('saved_for_later_list:is_manual_toggle_click', {
      isToggledOn: !tablistFilters.isManualFilterActive,
      currentFilters: tablistFilters,
    })
  }

  const handleIsMostVisitedButtonClicked = () => {
    setTablistFilters((prev) => {
      return {
        ...prev,
        isGroupByTopicsActive: !prev.isMostVisitedSortActive ? false : prev.isGroupByTopicsActive,
        isMostVisitedSortActive: !prev.isMostVisitedSortActive,
      }
    })

    captureAnalytics('saved_for_later_list:most_visited_toggle_click', {
      isToggledOn: !tablistFilters.isMostVisitedSortActive,
      currentFilters: tablistFilters,
    })
  }

  const handleIsGroupByTopicsButtonClicked = () => {
    setTablistFilters((prev) => {
      return {
        isManualFilterActive: false,
        isMostVisitedSortActive: false,
        isGroupByTopicsActive: !prev.isGroupByTopicsActive,
      }
    })

    captureAnalytics('saved_for_later_list:group_by_topics_toggle_click', {
      isToggledOn: !tablistFilters.isGroupByTopicsActive,
      currentFilters: tablistFilters,
    })
  }

  const handleIsClosedByMeButtonClicked = () => {
    setIsComingSoonModalOpen(true)

    captureAnalytics('saved_for_later_list:closed_by_me_toggle_click', {
      isToggledOn: false,
      currentFilters: tablistFilters,
    })
  }

  const onDismissComingSoonModal = () => {
    captureAnalytics('saved_for_later_list:coming_soon_modal_dismiss_click')
    setIsComingSoonModalOpen(false)
  }

  const handleDismissSavedPagesLimitModal = () => {
    captureAnalytics('saved_for_later_list:saved_pages_limit_modal_dismiss_click')
    setIsSavedPagesLimitModalOpen(false)
  }

  const handleSubmitSavedPagesLimitModal = () => {
    captureAnalytics('saved_for_later_list:saved_pages_limit_modal_upgrade_click')
    setIsSavedPagesLimitModalOpen(false)
    navigate('/saved#pricing')
  }

  return (
    <Box>
      <Box m="4px 0 16px 0px">
        {!isSelectionActive && (
          <Flex gap={'8px'} alignItems={'center'}>
            <ToggleButton
              icon={<IoFilter />}
              label={'Group by Topics'}
              isToggledOn={tablistFilters.isGroupByTopicsActive}
              onClick={handleIsGroupByTopicsButtonClicked}
            />
            <ToggleButton
              icon={<IoFilter />}
              label={'Saved by Me'}
              isToggledOn={tablistFilters.isManualFilterActive}
              onClick={handleIsManualButtonClicked}
            />
            <ToggleButton
              icon={<IoFilter />}
              label={'Most Visited'}
              isToggledOn={tablistFilters.isMostVisitedSortActive}
              onClick={handleIsMostVisitedButtonClicked}
            />
            <ToggleButton
              icon={<IoFilter />}
              label={'Closed by Me'}
              isToggledOn={false}
              onClick={handleIsClosedByMeButtonClicked}
            />
          </Flex>
        )}

        <MultiSelectToolbar />
      </Box>

      {!savedPages && (
        <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
          <Spinner color="blue.500" size="lg" speed="1s" />
        </div>
      )}

      {!tablistFilters.isManualFilterActive && savedPages && savedPages.length === 0 && (
        <div className="flex-center" style={{ width: '100%' }}>
          <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
            Check back later! Your saved tabs will be right here.
          </Text>
        </div>
      )}

      {tablistFilters.isManualFilterActive && filteredPages && filteredPages.length === 0 && (
        <TabSweepZeroStateContainer>
          <Text>{`"Saved by Me" tabs will appear when `}</Text>
          <Text>{`you manually `}</Text>
          <Text>{`save an `}</Text>
          <Text>{`open `}</Text>
          <Text>{`tab `}</Text>
          <Text>{`with`}</Text>
          <span className="flex-center" style={{ padding: '0 4px' }}>
            <FiDownload style={{ width: '16px', height: '16px', color: '#585858' }} />
          </span>
          <Text>{`in `}</Text>
          <Text>{`${APP_NAME}, `}</Text>
          <Text>{`or use the ${APP_NAME} extension icon`}</Text>
          <span className="flex-center" style={{ padding: '0 4px' }}>
            <img
              style={{ width: '16px', height: '16px', color: '#585858' }}
              src="/logo-128.png"
              alt={`${APP_NAME} logo icon`}
            />
          </span>
          <Text>{`while `}</Text>
          <Text>{`looking `}</Text>
          <Text>{`at that tab.`}</Text>
        </TabSweepZeroStateContainer>
      )}

      <div>
        {!tablistFilters.isGroupByTopicsActive &&
          filteredPages?.map((t, idx) => {
            return (
              <PutasideTabController
                key={t.id}
                id={t.id}
                page={t}
                onDeleteTablistPage={deleteTablistPage}
                shouldShowDeleteIcon={true}
                queryValue={''}
                index={idx}
                showAiSuggestion={!!t.ai_suggestion}
                numTotalResults={filteredPages.length}
                areaName={TABLIST_AREA_NAME_ENUM.RecentlySaved}
                spacingVariant={PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM.SAVED_FOR_LATER}
                onOpenMoveMenu={handleOpenMoveSavedItemModal}
                isHighlighted={highlightedPageIds && highlightedPageIds.includes(t.id)}
                isSelected={t.id in selectedPages}
                isTabAboveSelected={idx > 0 && filteredPages[idx - 1].id in selectedPages}
                isTabBelowSelected={
                  idx < filteredPages.length - 1 && filteredPages[idx + 1].id in selectedPages
                }
                onSelected={handlePageSelection}
                removeSelection={handleRemovePageSelection}
                isSelectionDisabled={isFolderCreationModeActive}
                shouldShowFolderCreationAddIcon={isFolderCreationModeActive}
                isPendingInFolderCreation={pendingFolderCreationPages.some(
                  (p) => p.instance_id === t.instance_id,
                )}
              />
            )
          })}
        {tablistFilters.isGroupByTopicsActive &&
          groupedPages &&
          groupedPages.map((item, idx) => {
            if (Array.isArray(item)) {
              const groupId = item[0].group_id
              if (groupId === undefined) {
                return <React.Fragment key={item[0].id} />
              }

              const group: TablistPageTopicGroupType = groups?.[groupId] ?? {
                title: 'Untitled Group',
                last_visited_time: 0,
              }

              return (
                <PutasideTopicGroup
                  key={`${groupId}`}
                  groupId={groupId}
                  index={idx}
                  pages={item}
                  title={group.title}
                  showAiSuggestion={!!group.ai_suggestion}
                  aiSuggestionReason={group.ai_suggestion?.reason}
                  lastVisitedTime={group.last_visited_time}
                  numTotalResults={savedPages?.length ?? 0}
                  highlightedPageIds={highlightedPageIds}
                  selectedPages={selectedPages}
                  onDeleteTablistPage={deleteTablistPage}
                  onOpenPageMoveMenu={handleOpenMoveSavedItemModal}
                  onPageSelected={handlePageSelection}
                  removePageSelection={handleRemovePageSelection}
                />
              )
            } else {
              const prev = idx > 0 ? groupedPages[idx - 1] : false
              const prevTab = prev && !Array.isArray(prev) ? prev : false
              const next = idx < groupedPages.length - 1 ? groupedPages[idx + 1] : false
              const nextTab = next && !Array.isArray(next) ? next : false
              const isTabAboveSelected = prevTab && prevTab.id in selectedPages
              const isTabBelowSelected = nextTab && nextTab.id in selectedPages

              return (
                <PutasideTabController
                  key={item.id}
                  id={item.id}
                  page={item}
                  showAiSuggestion={!!item.ai_suggestion}
                  onDeleteTablistPage={deleteTablistPage}
                  shouldShowDeleteIcon={true}
                  queryValue={''}
                  index={idx}
                  numTotalResults={savedPages?.length ?? 0}
                  areaName={TABLIST_AREA_NAME_ENUM.RecentlySaved}
                  spacingVariant={PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM.SAVED_FOR_LATER}
                  onOpenMoveMenu={handleOpenMoveSavedItemModal}
                  isHighlighted={highlightedPageIds && highlightedPageIds.includes(item.id)}
                  isSelected={item.id in selectedPages}
                  isTabAboveSelected={isTabAboveSelected}
                  isTabBelowSelected={isTabBelowSelected}
                  onSelected={handlePageSelection}
                  removeSelection={handleRemovePageSelection}
                  isSelectionDisabled={isFolderCreationModeActive}
                  shouldShowFolderCreationAddIcon={isFolderCreationModeActive}
                  isPendingInFolderCreation={pendingFolderCreationPages.some(
                    (p) => p.instance_id === item.instance_id,
                  )}
                />
              )
            }
          })}
      </div>

      {/* START LOAD MORE BUTTON FOR TABLIST */}
      {doMoreSavedPagesExist !== undefined && (
        <Flex w="100%" alignItems="center" justifyContent="flex-end">
          {doMoreSavedPagesExist && (
            <Button
              key="extra"
              size="sm"
              fontSize={12}
              fontWeight={500}
              borderRadius={'16px'}
              mt={'8px'}
              h="32px"
              minH="32px"
              w="100%"
              px={2}
              onClick={onClickShowMoreSavedPages}
              bg="#F6F6F6"
              color="#585858"
              _hover={{ bg: '#EBEBEB' }}
              isLoading={isMoreSavedPagesLoading}
            >
              {`Show older`}
            </Button>
          )}
          {!doMoreSavedPagesExist && (savedPages?.length ?? 0) !== 0 && (
            <Text
              fontSize={12}
              fontWeight={500}
              mt="8px"
              w="100%"
              px={2}
              color="#A7A7A7"
              textAlign="center"
            >
              {`You've reached the end of your saved tabs history`}
            </Text>
          )}
        </Flex>
      )}
      {/* END LOAD MORE BUTTON FOR TABLIST */}

      <SavedPagesLimitModal
        isOpen={isSavedPagesLimitModalOpen}
        onCancel={handleDismissSavedPagesLimitModal}
        onSubmit={handleSubmitSavedPagesLimitModal}
      />

      <ComingSoonModal isOpen={isComingSoonModalOpen} onClose={onDismissComingSoonModal} />

      <MoveItemModal pageInfo={moveSavedItemPageInfo} setPageInfo={setMoveSavedItemPageInfo} />
    </Box>
  )
}

export default SavedForLaterList
