import React, { useState, useContext, createContext, FC, useEffect, useMemo } from 'react'
import { sendMessageToExtension } from '../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../extension/models/messaging.types'
import { UserInfoType } from '../models/user.types'

export type ExtensionHealthType = {
  version: number
  userInfo?: UserInfoType
}

type ExtensionHealthContextType = {
  // Undefined means that the extension health is being loaded
  // Null means that the extension health is not available (e.g. extension not installed)
  extensionHealth: ExtensionHealthType | undefined | null
  forceReload: () => void
}

export abstract class ExtensionHealthContextBackend {
  extensionHealth: ExtensionHealthType | undefined | null = undefined

  abstract getExtensionHealth(): Promise<ExtensionHealthType | null>
}

export class WebAppExtensionHealthContextBackend extends ExtensionHealthContextBackend {
  async getExtensionHealth(): Promise<ExtensionHealthType | null> {
    const result = await sendMessageToExtension(
      BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_EXTENSION_HEALTH,
    ).catch(() => {
      return null
    })
    const extensionHealth = result as ExtensionHealthType | null
    this.extensionHealth = extensionHealth
    return extensionHealth
  }
}

const ExtensionHealthContext = createContext<ExtensionHealthContextType>({
  extensionHealth: undefined,
  forceReload: () => {
    console.error(
      'ERROR: ExtensionHealthContext forceReload function called before being initialized',
    )
  },
})

export const useExtensionHealthContext = (): ExtensionHealthContextType => {
  return useContext(ExtensionHealthContext)
}

export const ExtensionHealthContextProvider: FC<{
  backend: ExtensionHealthContextBackend
  children: React.ReactNode
}> = ({ backend, children }) => {
  const [extensionHealth, setExtensionHealth] = useState<ExtensionHealthType | undefined | null>(
    undefined,
  )
  const [forceReloadVal, setForceReloadVal] = useState<number>(0)

  useEffect(() => {
    backend
      .getExtensionHealth()
      .then((result) => {
        console.log('Skipper Extension Health', result)
        if (!result) {
          throw new Error('Extension health not available')
        }

        setExtensionHealth({
          version: result.version,
          userInfo: result.userInfo,
        })
      })
      .catch(() => {
        setExtensionHealth(null)
      })
  }, [backend, forceReloadVal])

  const value = useMemo(() => {
    return {
      extensionHealth,
      forceReload: () => {
        setExtensionHealth(undefined)
        setForceReloadVal((prev) => prev + 1)
      },
    }
  }, [extensionHealth])

  return <ExtensionHealthContext.Provider value={value}>{children}</ExtensionHealthContext.Provider>
}
