import React, { FC, useEffect } from 'react'
import { Flex, Text, Button, Box, IconButton, Tooltip } from '@chakra-ui/react'
import { useUserContext } from '../../contexts/UserContext'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../models/browserStorage.types'
import { useLocalStorageBoolean } from '../../utils/genericHooks'
import { MdClose } from 'react-icons/md'
import { sendMessageToExtension } from '../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../extension/models/messaging.types'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM, FeatureFlagValuesType } from '../../models/feature_flags.types'
import { EXTENSION_SETTINGS_KEYS } from '../../extension/models/extensionStorage.types'
import { ExtensionSettingsType } from '../../extension/models/extensionStorage.types'
import { CHROME_NEW_TAB_PAGE_URL } from '../../constants'

interface Props {
  children: React.ReactNode
}

const NotificationBannerWrapper: FC<Props> = (props) => {
  const { children } = props
  const { captureAnalytics, userInfo } = useUserContext()
  const { featureFlags, isDefault: areFeatureFlagsLoading } = useFeatureFlagContext()
  const isNewTabOverrideFFEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.ShouldOverrideNewTabPage]

  const [isNewTabOverrideNotificationVisible, setIsNewTabOverrideNotificationVisible] =
    useLocalStorageBoolean(WEBAPP_LOCAL_STORAGE_KEYS.IsNewTabOverrideNotificationVisible, null)

  const handleClickPrimaryAction = async () => {
    captureAnalytics('new_tab_override_notification_banner:primary_action_click')
    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
      urls: [CHROME_NEW_TAB_PAGE_URL],
      shouldActivate: true,
    })
    setIsNewTabOverrideNotificationVisible(false)
  }

  const handleClickDismiss = () => {
    captureAnalytics('new_tab_override_notification_banner:dismiss_click')
    setIsNewTabOverrideNotificationVisible(false)
  }

  useEffect(() => {
    if (!userInfo || areFeatureFlagsLoading) {
      return
    }

    if (!isNewTabOverrideFFEnabled) {
      return
    }

    if (isNewTabOverrideNotificationVisible === false) {
      return
    }

    async function initBannerVisibility() {
      try {
        // We need to check if the extension setting is enabled (it may have already been disabled by the user)
        const settings = (await sendMessageToExtension(
          BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_EXTENSION_SETTINGS,
        )) as ExtensionSettingsType
        const isNewTabSettingEnabled =
          settings[EXTENSION_SETTINGS_KEYS.IS_NEW_TAB_PAGE_OVERRIDE_ENABLED]
        if (isNewTabSettingEnabled) {
          setIsNewTabOverrideNotificationVisible(true)
        } else {
          // If the setting is disabled, this could be bc of a race condition where the extension feature flags are not yet updated
          //so we force a refresh here and check if the feature flag is enabled
          const extensionFeatureFlags = (await sendMessageToExtension(
            BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_FEATURE_FLAGS,
            { forceRefresh: true },
          )) as { featureFlags: FeatureFlagValuesType } | undefined
          const extensionIsNewTabPageOverrideEnabled =
            extensionFeatureFlags?.featureFlags[FEATURE_FLAG_KEYS_ENUM.ShouldOverrideNewTabPage]
          if (!extensionIsNewTabPageOverrideEnabled) {
            captureAnalytics('new_tab_override_notification_banner:extension_flag_disabled')
            return
          }

          setIsNewTabOverrideNotificationVisible(false)
        }

        captureAnalytics('new_tab_override_notification_banner:init', {
          isNewTabSettingEnabled,
          isNewTabOverrideNotificationVisible,
          isFirstInit: isNewTabOverrideNotificationVisible === null,
        })
      } catch (e) {
        console.error('Error fetching settings', e)
      }
    }

    const timeout = setTimeout(() => {
      //Add delay to allow onboarding to initialize (which will hide the banner for new users)
      const isVisibleDelayed = window.localStorage.getItem(
        WEBAPP_LOCAL_STORAGE_KEYS.IsNewTabOverrideNotificationVisible,
      )
      if (isVisibleDelayed === 'false') {
        return
      }

      initBannerVisibility()
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [
    captureAnalytics,
    userInfo,
    isNewTabOverrideNotificationVisible,
    setIsNewTabOverrideNotificationVisible,
    areFeatureFlagsLoading,
    isNewTabOverrideFFEnabled,
  ])

  if (!isNewTabOverrideNotificationVisible) {
    return children
  }

  return (
    <Flex h="100%" w="100%" flexDirection="column">
      <Flex
        className="is-ellipsis-1"
        w="100%"
        h="40px"
        alignItems="center"
        justifyContent="center"
        color="white"
        bg="#0071E3"
        p="8px"
      >
        <Text fontSize="14px" fontWeight={500} lineHeight="22px" whiteSpace="pre-wrap">
          {`New: Skipper is now your new tab page!`}
        </Text>
        <Button
          h="24px"
          w="95px"
          ml="24px"
          borderRadius="100px"
          backgroundColor="#FFF"
          color="#0071E3"
          fontWeight={500}
          fontSize="12px"
          lineHeight="16px"
          onClick={handleClickPrimaryAction}
        >
          {`Check it out`}
        </Button>

        <Tooltip placement="bottom" label="Dismiss">
          <IconButton
            variant="outline"
            border="none"
            position="absolute"
            right="8px"
            aria-label="Dismiss banner"
            as="a"
            icon={<MdClose color="white" />}
            onClick={handleClickDismiss}
            _hover={{ bg: '#ebebeb4b' }}
            height="24px"
            width="24px"
            minWidth="24px"
          />
        </Tooltip>
      </Flex>
      <Box h="calc(100% - 40px)" w="100%">
        {children}
      </Box>
    </Flex>
  )
}

export default NotificationBannerWrapper
