import { createSlice } from '@reduxjs/toolkit'
import { TablistPageType } from '../models/tablist_pages.types'
import { ReduxStateType } from './baseStore'

export interface ProjectsSliceStateType {
  newlyCreatedProjectId?: string
  isFolderCreationModeActive?: boolean
  pendingFolderCreationPages: TablistPageType[]
  pendingFolderCreationTitle: string
  suggestedFolderCreationTitles?: string[]
  suggestedFolderCreationPages?: TablistPageType[]
}

const initialState: ProjectsSliceStateType = {
  newlyCreatedProjectId: undefined,
  isFolderCreationModeActive: false,
  pendingFolderCreationPages: [],
  pendingFolderCreationTitle: '',
  suggestedFolderCreationTitles: undefined,
  suggestedFolderCreationPages: undefined,
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setNewlyCreatedProjectId(state, action: { payload: string | undefined }) {
      state.newlyCreatedProjectId = action.payload
    },
    activateFolderCreationMode(
      state,
      action: { payload: { pages?: TablistPageType[]; title?: string } },
    ) {
      state.isFolderCreationModeActive = true
      if (action.payload?.pages) {
        state.pendingFolderCreationPages = action.payload.pages
      }
      if (action.payload?.title) {
        state.pendingFolderCreationTitle = action.payload.title
      }
    },
    cancelFolderCreationMode(state) {
      state.isFolderCreationModeActive = false
      state.pendingFolderCreationPages = []
      state.pendingFolderCreationTitle = ''
      state.suggestedFolderCreationTitles = undefined
      state.suggestedFolderCreationPages = undefined
    },
    setPendingFolderCreationTitle(state, action: { payload: string }) {
      state.pendingFolderCreationTitle = action.payload
      state.suggestedFolderCreationTitles = undefined
      state.suggestedFolderCreationPages = undefined
    },
    addPendingFolderCreationPages(state, action: { payload: TablistPageType[] }) {
      state.pendingFolderCreationPages.push(...action.payload)
      state.suggestedFolderCreationTitles = undefined
      state.suggestedFolderCreationPages = undefined
    },
    removePendingFolderCreationPage(state, action: { payload: string }) {
      state.pendingFolderCreationPages = state.pendingFolderCreationPages.filter(
        (page) => String(page.instance_id ?? page.id) !== action.payload,
      )
      state.suggestedFolderCreationTitles = undefined
      state.suggestedFolderCreationPages = undefined
    },
    setSuggestedFolderCreationTitles(state, action: { payload: string[] }) {
      state.suggestedFolderCreationTitles = action.payload
    },
    setSuggestedFolderCreationPages(state, action: { payload: TablistPageType[] }) {
      state.suggestedFolderCreationPages = action.payload
    },
  },
})

export const selectIsFolderCreationModeActive = (state: ReduxStateType) =>
  state.projects.isFolderCreationModeActive

export const selectPendingFolderCreationPages = (state: ReduxStateType) =>
  state.projects.pendingFolderCreationPages

export const selectPendingFolderCreationTitle = (state: ReduxStateType) =>
  state.projects.pendingFolderCreationTitle

export const selectSuggestedFolderCreationTitles = (state: ReduxStateType) =>
  state.projects.suggestedFolderCreationTitles

export const selectSuggestedFolderCreationPages = (state: ReduxStateType) =>
  state.projects.suggestedFolderCreationPages

export const {
  setNewlyCreatedProjectId,
  activateFolderCreationMode,
  setPendingFolderCreationTitle,
  addPendingFolderCreationPages,
  removePendingFolderCreationPage,
  cancelFolderCreationMode,
  setSuggestedFolderCreationTitles,
  setSuggestedFolderCreationPages,
} = projectsSlice.actions

export default projectsSlice.reducer
