import React, { FC, useCallback, useRef } from 'react'
import { Text, Spinner, Box, ToastId, useToast, UseToastOptions } from '@chakra-ui/react'
import PutasideTabController from './PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from './PutasideTabList'
import { useDeleteOpenTabsMutation, useGetOpenTabsQuery } from '../../../webapp/redux/extension'
import NotificationToast from '../../../components/NotificationToast'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { useAppendTablistPagesMutation } from '../../../redux/services/skeema/tablist_pages.endpoints'
import {
  selectIsFolderCreationModeActive,
  selectPendingFolderCreationPages,
} from '../../../redux/projectsSlice'
import { useReduxSelector } from '../../../redux/baseStore'

const TOAST_DURATION_MS = 3000
const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

export interface TablistFiltersType {
  isManualFilterActive: boolean
  isMostVisitedSortActive: boolean
  isGroupByTopicsActive: boolean
}

const OpenTabsList: FC = () => {
  const isFolderCreationModeActive = useReduxSelector(selectIsFolderCreationModeActive)
  const pendingFolderCreationPages = useReduxSelector(selectPendingFolderCreationPages)

  const { data: openTabPages } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })

  const toast = useToast(USE_TOAST_OPTIONS)
  const toastIdRef = useRef<ToastId | undefined>(undefined)

  const [appendTablistPages] = useAppendTablistPagesMutation()
  const [deleteOpenTabs] = useDeleteOpenTabsMutation()
  const deleteOpenTab = useCallback(
    async (params: { id: string }) => {
      await deleteOpenTabs({ ids: [params.id] }).unwrap()
    },
    [deleteOpenTabs],
  )

  const saveAndCloseOpenTab = useCallback(
    async (params: { page: TablistPageType }) => {
      const { page } = params
      const id = page.id

      await appendTablistPages({ pages: [page], isManual: true })
        .unwrap()
        .then((res) => {
          if (res.ok) {
            deleteOpenTab({ id })
          }
        })
        .catch((error) => {
          console.error(error)
        })

      if (toastIdRef.current) {
        toast.close(toastIdRef.current)
        toastIdRef.current = undefined
      }
      toastIdRef.current = toast({
        duration: TOAST_DURATION_MS,
        containerStyle: { minWidth: 'auto' },
        render: () => <NotificationToast message={`Tab has been saved to 'All saved tabs'`} />,
      })
    },
    [appendTablistPages, deleteOpenTab, toast],
  )

  return (
    <Box>
      {!openTabPages && (
        <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
          <Spinner color="blue.500" size="lg" speed="1s" />
        </div>
      )}

      {openTabPages && openTabPages.length === 0 && (
        <div className="flex-center" style={{ width: '100%' }}>
          <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
            Check back later! Your open tabs will be right here.
          </Text>
        </div>
      )}

      <div>
        {openTabPages?.map((t, idx) => {
          return (
            <PutasideTabController
              key={t.id}
              id={t.id}
              page={t}
              onDeleteOpenTab={deleteOpenTab}
              shouldShowDeleteIcon={true}
              onSaveOpenTab={saveAndCloseOpenTab}
              isSelectionDisabled={isFolderCreationModeActive}
              shouldShowFolderCreationAddIcon={isFolderCreationModeActive}
              isPendingInFolderCreation={pendingFolderCreationPages.some(
                (p) => p.instance_id === t.instance_id,
              )}
              showTimeString={false}
              queryValue={''}
              index={idx}
              numTotalResults={openTabPages.length}
              areaName={TABLIST_AREA_NAME_ENUM.Open}
            />
          )
        })}
      </div>
    </Box>
  )
}

export default OpenTabsList
