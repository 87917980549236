import React, { FC } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const ComingSoonModal: FC<Props> = (props) => {
  const { isOpen, onClose } = props

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={'16px'}>
        <ModalHeader>{`Coming soon!`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="16px" color="black">
            {`We're working on this feature now. Stay tuned!`}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            size="md"
            variant="solid"
            borderRadius="100px"
            paddingRight="24px"
            paddingLeft="24px"
            backgroundColor={'black'}
            color={'white'}
            _hover={{
              backgroundColor: '#585858',
            }}
            onClick={onClose}
            autoFocus
          >
            Dismiss
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ComingSoonModal
