import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { ONBOARDING_TUTORIAL_STEPS } from './onboarding.types'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../../models/browserStorage.types'
import OnboardingDialogs from './OnboardingDialogs'
import { Box } from '@chakra-ui/react'
import Portal from '../../../widgets/Portal'
import { useLazyGetOpenTabsQuery } from '../../../webapp/redux/extension'
import ExpandWindowMessage from './ExpandWindowMessage'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { useUserContext } from '../../../contexts/UserContext'
import { useLazyGetActiveProjectsQuery } from '../../../redux/services/skeema/projects.endpoints'
import { useLazyGetSmartSessionsWithOpenTabsV2Query } from '../../../redux/services/skeema/smart_sessions.endpoints'
import { useLazyGetTablistPagesQuery } from '../../../redux/services/skeema/tablist_pages.endpoints'
import {
  sendMessageToBackground,
  sendMessageToBackgroundWithTimeout,
} from '../../../extension/pages/contentScript/messagingUtils'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { CHROME_STORAGE_LOCAL_KEYS } from '../../../extension/models/extensionStorage.types'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'
import { useLocalStorageBoolean } from '../../../utils/genericHooks'

export const getOnboardingStepSequence = (params: {
  numOpenTabs: number
  numUnusedTabs: number
  isDefaultGuidedAutoSaveEnabled: boolean
}): ONBOARDING_TUTORIAL_STEPS[] => {
  const { numOpenTabs, numUnusedTabs, isDefaultGuidedAutoSaveEnabled } = params

  let steps = [
    ONBOARDING_TUTORIAL_STEPS.Preface,
    ONBOARDING_TUTORIAL_STEPS.Stats,
    ONBOARDING_TUTORIAL_STEPS.Intro,
    ONBOARDING_TUTORIAL_STEPS.Intro2,
    ONBOARDING_TUTORIAL_STEPS.TabGroups,
    ONBOARDING_TUTORIAL_STEPS.PinTabs,
    isDefaultGuidedAutoSaveEnabled
      ? ONBOARDING_TUTORIAL_STEPS.GuidedAutoSave
      : ONBOARDING_TUTORIAL_STEPS.AutoSaveConfig,
    ONBOARDING_TUTORIAL_STEPS.TabList,
    ONBOARDING_TUTORIAL_STEPS.DraftProjects,
    ONBOARDING_TUTORIAL_STEPS.Conclusion,
  ]

  if (numOpenTabs < 6) {
    steps = steps.filter(
      (step) =>
        step !== ONBOARDING_TUTORIAL_STEPS.Stats && step !== ONBOARDING_TUTORIAL_STEPS.Intro,
    )
  }

  if (numUnusedTabs === 0) {
    steps = steps.filter((step) => step !== ONBOARDING_TUTORIAL_STEPS.Intro)
  }

  return steps
}

interface Props {
  currentStep: ONBOARDING_TUTORIAL_STEPS | null
  setCurrentStep: React.Dispatch<ONBOARDING_TUTORIAL_STEPS | null>
  isOnboardingTutorialEnabled: boolean
}

const OnboardingFlowController: FC<Props> = (props) => {
  const { currentStep, setCurrentStep, isOnboardingTutorialEnabled } = props
  const { captureAnalytics } = useUserContext()
  const { projectConfig, featureFlags, isDefault: areFeatureFlagsLoading } = useFeatureFlagContext()
  const maxNumProjects = projectConfig.maxNumProjects
  const maxNumProjectPages = projectConfig.maxNumProjectsPages
  const isDefaultGuidedAutoSaveEnabled =
    featureFlags[FEATURE_FLAG_KEYS_ENUM.IsDefaultGuidedAutoSave]
  const isNewTabOverrideFFEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.ShouldOverrideNewTabPage]

  const [, setIsNewTabOverrideNotificationVisible] = useLocalStorageBoolean(
    WEBAPP_LOCAL_STORAGE_KEYS.IsNewTabOverrideNotificationVisible,
    null,
  )

  const dialogPositionRef = useRef<HTMLDivElement | null>(null)
  const [onboardingSteps, setOnboardingSteps] = useState<ONBOARDING_TUTORIAL_STEPS[] | undefined>(
    undefined,
  )
  const [wouldCreateProjectsFromTabgroups, setWouldCreateProjectsFromTabgroups] =
    useState<boolean>(false)
  const [numTabGroups, setNumTabGroups] = useState<number | undefined>(undefined)

  const shouldShowOnboarding = !!isOnboardingTutorialEnabled && !!onboardingSteps && !!currentStep
  const currentStepIdx = shouldShowOnboarding
    ? onboardingSteps.findIndex((step) => step === currentStep)
    : undefined
  const numTotalSteps = shouldShowOnboarding ? onboardingSteps.length : undefined

  const [triggerTablistPagesQuery] = useLazyGetTablistPagesQuery({
    refetchOnFocus: false,
  })
  const [triggerSmartSessionsQuery] = useLazyGetSmartSessionsWithOpenTabsV2Query({
    refetchOnFocus: false,
  })
  const [triggerActiveProjectsQuery] = useLazyGetActiveProjectsQuery({ refetchOnFocus: false })
  const [triggerOpenTabsQuery] = useLazyGetOpenTabsQuery({
    refetchOnFocus: false,
  })

  useEffect(() => {
    // Remove focus from any active element (e.g. autofocused search bar)
    if (shouldShowOnboarding) {
      const el = window.document.activeElement as HTMLElement | null
      el?.blur()
    }
  }, [shouldShowOnboarding])

  useEffect(() => {
    //----------------------------------------
    async function initOnboardingSteps() {
      const isExtensionOnboardingCompleted = await sendMessageToBackgroundWithTimeout(
        BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_IS_ONBOARDING_COMPLETED,
        undefined,
        3000,
      ).catch((err) => {
        console.error(err)
        return false
      })

      if (isExtensionOnboardingCompleted) {
        setCurrentStep(ONBOARDING_TUTORIAL_STEPS.DONE)
        captureAnalytics('onboarding_tutorial:extension_onboarding_completed_detect', {
          step: ONBOARDING_TUTORIAL_STEPS.DONE,
          isExtensionOnboardingCompleted,
        })
        return
      }

      const openTabs = (await triggerOpenTabsQuery(undefined)).data ?? []
      const numOpenTabs = openTabs.length
      const numUnusedTabs = openTabs.filter(
        (t) => t.last_access_timestamp_ms < Date.now() - 24 * 60 * 60 * 1000,
      ).length
      const tabGroupCounts = openTabs.reduce(
        (acc, t) => {
          const groupId = t.group_id ?? -1
          if (groupId === -1) {
            return acc
          }
          if (acc[groupId] === undefined) {
            acc[groupId] = 0
          }
          acc[groupId]++
          return acc
        },
        {} as Record<number, number>,
      )

      const numTabGroups = Object.keys(tabGroupCounts).length
      setNumTabGroups(numTabGroups)
      const maxNumTabsInTabGroup = Math.max(...Object.values(tabGroupCounts))
      setWouldCreateProjectsFromTabgroups(
        numTabGroups > 0 &&
          numTabGroups <= maxNumProjects - 1 &&
          maxNumTabsInTabGroup <= maxNumProjectPages,
      )

      const steps = getOnboardingStepSequence({
        numOpenTabs,
        numUnusedTabs,
        isDefaultGuidedAutoSaveEnabled,
      }) // Add any ff config here

      if (isNewTabOverrideFFEnabled) {
        setIsNewTabOverrideNotificationVisible(false)
      }

      captureAnalytics('onboarding_tutorial:steps_loaded', {
        steps,
        numOpenTabs,
        numUnusedTabs,
        numTabGroups,
        maxNumTabsInTabGroup,
        maxNumProjects,
        maxNumProjectPages,
      })

      setOnboardingSteps(steps)
    }
    // End function definition----------------------------------------

    if (areFeatureFlagsLoading) {
      return
    }

    if (currentStep === ONBOARDING_TUTORIAL_STEPS.DONE) {
      void sendMessageToBackground(
        BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.UPDATE_CHROME_STORAGE_ITEM,
        {
          storageType: 'local',
          key: CHROME_STORAGE_LOCAL_KEYS.IsOnboardingCompleted,
          value: true,
        },
      )
      return
    }

    if (!onboardingSteps) {
      initOnboardingSteps()
      return
    }

    if (currentStep === null) {
      setCurrentStep(onboardingSteps[0])
      return
    }

    const isInvalidStep = onboardingSteps && !onboardingSteps.includes(currentStep)
    if (isInvalidStep) {
      const newStep = onboardingSteps[0]
      setCurrentStep(newStep)
      captureAnalytics('onboarding_tutorial:invalid_step_reset', {
        invalidStep: currentStep,
        step: newStep,
        steps: onboardingSteps,
      })
    }
  }, [
    areFeatureFlagsLoading,
    isDefaultGuidedAutoSaveEnabled,
    captureAnalytics,
    currentStep,
    maxNumProjectPages,
    maxNumProjects,
    onboardingSteps,
    setCurrentStep,
    triggerOpenTabsQuery,
    isNewTabOverrideFFEnabled,
    setIsNewTabOverrideNotificationVisible,
  ])

  const logOnboardingCompletion = useCallback(async () => {
    const projects = (await triggerActiveProjectsQuery(undefined)).data
    const openTabs = (await triggerOpenTabsQuery(undefined)).data
    const tablistPages = (await triggerTablistPagesQuery(undefined)).data
    const smartSessions = (await triggerSmartSessionsQuery({ openTabs })).data

    const savedPages = tablistPages?.tablist
    const mostUsedTabs = tablistPages?.most_visited_pages
    const numSavedPages = savedPages?.length || 0
    const numMostUsedPages = mostUsedTabs?.length || 0
    const numOpenTabs = openTabs?.length || 0
    const numProjects = projects?.length || 0
    const numSmartSessions = smartSessions?.sessions?.length || 0

    const numStaticProjects =
      projects?.filter((p) => p.title === '🧠 Skipper Resources').length || 0

    captureAnalytics('onboarding_tutorial:completed', {
      numSmartSessions,
      numTabGroups,
      numStaticProjects,
      numTotalProjects: numProjects,
      numMostUsedPages,
      numSavedPages,
      numOpenTabs,
    })
  }, [
    numTabGroups,
    captureAnalytics,
    triggerActiveProjectsQuery,
    triggerOpenTabsQuery,
    triggerSmartSessionsQuery,
    triggerTablistPagesQuery,
  ])

  const handleIncrementStep = useCallback(() => {
    if (currentStep === null || currentStepIdx === undefined || !onboardingSteps) {
      return
    }

    const newIdx = currentStepIdx + 1
    const newStep =
      newIdx < onboardingSteps.length ? onboardingSteps[newIdx] : ONBOARDING_TUTORIAL_STEPS.DONE
    captureAnalytics('onboarding_tutorial:step_completed', {
      step: currentStep,
      stepIdx: currentStepIdx,
      newStep,
      steps: onboardingSteps,
    })
    setCurrentStep(newStep)

    if (newStep === ONBOARDING_TUTORIAL_STEPS.DONE) {
      window.localStorage.setItem(WEBAPP_LOCAL_STORAGE_KEYS.IsReadyForPinningTutorial, 'true')
      logOnboardingCompletion()
    }
  }, [
    currentStepIdx,
    onboardingSteps,
    captureAnalytics,
    currentStep,
    setCurrentStep,
    logOnboardingCompletion,
  ])

  if (!shouldShowOnboarding) {
    return null
  }

  return (
    <Portal>
      <Box position="fixed" top={0} left={0} right={0} bottom={0} w="0" h="0" zIndex={2}>
        <Box
          ref={dialogPositionRef}
          position="fixed"
          bottom="32px"
          right={'32px'}
          transition={'right 600ms ease-in-out'}
          zIndex={1}
        >
          <OnboardingDialogs
            currentStep={currentStep}
            currentStepIdx={currentStepIdx ?? 0}
            numSteps={numTotalSteps ?? 0}
            onNext={handleIncrementStep}
            wouldCreateProjectsFromTabgroups={wouldCreateProjectsFromTabgroups}
          />
        </Box>
        <ExpandWindowMessage />
      </Box>
    </Portal>
  )
}

export default OnboardingFlowController
