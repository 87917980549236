import React, { FC, useEffect, useState } from 'react'
import {
  Flex,
  Text,
  Button,
  Divider,
  Heading,
  Icon,
  Spacer,
  Wrap,
  Spinner,
  Radio,
  Box,
  Switch,
} from '@chakra-ui/react'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { useUserContext } from '../../../contexts/UserContext'
import { HiSparkles } from 'react-icons/hi2'
import AutoSaveSettingsFormContent from './AutoSaveSettingsFormContent'
import { FEEDBACK_FORM_URL } from '../../../constants'
import AdminSettingsContent from './AdminSettingsContent'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import InternalSettingsContent from './InternalSettingsContent'
import {
  ExtensionSettingsType,
  EXTENSION_SETTINGS_KEYS,
} from '../../../extension/models/extensionStorage.types'

const SettingsForm: FC = () => {
  const { captureAnalytics } = useUserContext()
  const { featureFlags } = useFeatureFlagContext()
  const isDemoAccount = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsDemoAccount]
  const isDeveloper = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsDeveloper]
  const isInternal = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsAlphaUser]
  const shouldShowNewTabPageSettings = featureFlags[FEATURE_FLAG_KEYS_ENUM.ShouldOverrideNewTabPage]
  const isDefaultGuidedAutoSave = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsDefaultGuidedAutoSave]

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [settings, setSettings] = useState<
    | Pick<
        ExtensionSettingsType,
        | EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM
        | EXTENSION_SETTINGS_KEYS.OVERWHELM_THRESHOLD
        | EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED
        | EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_FREQUENCY_THRESHOLD
        | EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_INACTIVITY_THRESHOLD
        | EXTENSION_SETTINGS_KEYS.IS_NEW_TAB_PAGE_OVERRIDE_ENABLED
      >
    | undefined
  >(undefined)

  const {
    overwhelmThreshold,
    staleThresholdAfterOverwhelm,
    isGuidedAutoSaveEnabled,
    guidedAutoSaveFrequencyThreshold,
    guidedAutoSaveInactivityThreshold,
    isNewTabPageOverrideEnabled,
  } = settings || {}

  const handleGuidedAutomaticSaveChange = async (isGuided: boolean) => {
    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
      settings: {
        [EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED]: isGuided,
      },
    })
    setSettings((prev) =>
      prev
        ? {
            ...prev,
            [EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED]: isGuided,
          }
        : prev,
    )
    captureAnalytics('settings_page:guided_automatic_save_change', {
      isGuidedAutoSaveEnabled: isGuided,
    })
  }

  const handleChangeStaleThresholdAfterOverwhelm = async (value: number) => {
    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
      settings: {
        [EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM]: value,
      },
    })
    setSettings((prev) =>
      prev
        ? {
            ...prev,
            [EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM]: value,
          }
        : prev,
    )
    captureAnalytics('settings_page:stale_threshold_after_overwhelm_change', {
      staleThresholdAfterOverwhelm: value,
    })
  }

  const handleChangeOverwhelmThreshold = async (value: number) => {
    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
      settings: {
        [EXTENSION_SETTINGS_KEYS.OVERWHELM_THRESHOLD]: value,
      },
    })
    setSettings((prev) =>
      prev
        ? {
            ...prev,
            [EXTENSION_SETTINGS_KEYS.OVERWHELM_THRESHOLD]: value,
          }
        : prev,
    )
    captureAnalytics('settings_page:overwhelm_threshold_change', {
      overwhelmThreshold: value,
    })
  }

  const handleChangeGuidedAutoSaveFrequencyThreshold = async (value: number) => {
    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
      settings: {
        [EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_FREQUENCY_THRESHOLD]: value,
      },
    })
    setSettings((prev) =>
      prev
        ? {
            ...prev,
            [EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_FREQUENCY_THRESHOLD]: value,
          }
        : prev,
    )
    captureAnalytics('settings_page:guided_auto_save_frequency_threshold_change', {
      guidedAutoSaveFrequencyThreshold: value,
    })
  }

  const handleChangeGuidedAutoSaveInactivityThreshold = async (value: number) => {
    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
      settings: {
        [EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_INACTIVITY_THRESHOLD]: value,
      },
    })
    setSettings((prev) =>
      prev
        ? {
            ...prev,
            [EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_INACTIVITY_THRESHOLD]: value,
          }
        : prev,
    )
    captureAnalytics('settings_page:guided_auto_save_inactivity_threshold_change', {
      guidedAutoSaveInactivityThreshold: value,
    })
  }

  const handleNewTabPageOverrideChange = async (_e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prev) =>
      prev
        ? {
            ...prev,
            [EXTENSION_SETTINGS_KEYS.IS_NEW_TAB_PAGE_OVERRIDE_ENABLED]:
              !isNewTabPageOverrideEnabled,
          }
        : prev,
    )

    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
      settings: {
        [EXTENSION_SETTINGS_KEYS.IS_NEW_TAB_PAGE_OVERRIDE_ENABLED]: !isNewTabPageOverrideEnabled,
      },
    })

    captureAnalytics('settings_page:new_tab_page_override_change', {
      isNewTabPageOverrideEnabled: !isNewTabPageOverrideEnabled,
    })
  }

  useEffect(() => {
    setIsLoading(true)
    sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_EXTENSION_SETTINGS)
      .then((response) => {
        const settings = response as ExtensionSettingsType
        setSettings({
          [EXTENSION_SETTINGS_KEYS.OVERWHELM_THRESHOLD]:
            settings[EXTENSION_SETTINGS_KEYS.OVERWHELM_THRESHOLD],
          [EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM]:
            settings[EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM],
          [EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED]:
            settings[EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED],
          [EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_FREQUENCY_THRESHOLD]:
            settings[EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_FREQUENCY_THRESHOLD],
          [EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_INACTIVITY_THRESHOLD]:
            settings[EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_INACTIVITY_THRESHOLD],
          [EXTENSION_SETTINGS_KEYS.IS_NEW_TAB_PAGE_OVERRIDE_ENABLED]:
            settings[EXTENSION_SETTINGS_KEYS.IS_NEW_TAB_PAGE_OVERRIDE_ENABLED],
        })
        setIsLoading(false)
      })
      .catch((e) => {
        console.error('Error fetching settings', e)
      })
  }, [])

  if (isLoading) {
    return <Spinner size="xl" />
  }

  return (
    <Box>
      {isDefaultGuidedAutoSave && (
        <>
          <Box>
            <Box>
              <Radio
                key="automatic-save"
                value="automatic-save"
                isChecked={!isGuidedAutoSaveEnabled}
                onChange={() => handleGuidedAutomaticSaveChange(false)}
                colorScheme="gray"
              >
                <Heading as="h2" fontSize="14px" fontWeight={500} color="#585858" lineHeight="22px">
                  Full Automatic Save
                </Heading>
              </Radio>
              <Text mt="6px" ml="24px" fontSize="12px" lineHeight="16px" color="#585858">
                {`Skipper will save and close inactive tabs automatically. Perfect for anyone who wants a hands-off experience - recommended for the most decluttered and focused browsing.`}
              </Text>
            </Box>
            <Box mt="12px">
              <Radio
                key="guided-automatic-save"
                value="guided-automatic-save"
                isChecked={isGuidedAutoSaveEnabled}
                onChange={() => handleGuidedAutomaticSaveChange(true)}
                colorScheme="black"
              >
                <Heading as="h2" fontSize="14px" fontWeight={500} color="#585858" lineHeight="22px">
                  Guided Automatic Save
                </Heading>
              </Radio>
              <Text mt="6px" ml="24px" fontSize="12px" lineHeight="16px" color="#585858">
                {`Once a day when you return from a break, Skipper will suggest tabs to save and close. You can review these suggestions or dismiss them.`}
              </Text>
            </Box>
          </Box>
          <Divider my="24px" />
        </>
      )}
      <AutoSaveSettingsFormContent
        isGuidedAutoSaveEnabled={isGuidedAutoSaveEnabled}
        staleThresholdAfterOverwhelm={staleThresholdAfterOverwhelm}
        overwhelmThreshold={overwhelmThreshold}
        onChangeStaleThresholdAfterOverwhelm={handleChangeStaleThresholdAfterOverwhelm}
        onChangeOverwhelmThreshold={handleChangeOverwhelmThreshold}
      />

      {shouldShowNewTabPageSettings && (
        <>
          <Divider my="24px" />
          <Flex mb="16px" w="100%" align={'center'}>
            <Heading as="h3" fontSize="14px" fontWeight={500}>
              New Tab Page
            </Heading>
          </Flex>
          <Flex justifyContent="space-between">
            <Text fontSize="12px" lineHeight="16px" pt="4px" color="#585858">
              Display my saved Skipper tabs when I open a new tab in Chrome
            </Text>
            <Switch
              isChecked={isNewTabPageOverrideEnabled}
              onChange={handleNewTabPageOverrideChange}
            />
          </Flex>
        </>
      )}

      <Divider my="24px" />
      <Wrap mb="16px" w="100%" align={'center'}>
        <Heading as="h3" fontSize="14px" fontWeight={500}>
          Adaptive Automatic Save <Icon as={HiSparkles} />
        </Heading>
        <Spacer />
        <Flex align="center">
          <Text
            fontSize="12px"
            lineHeight="16px"
            fontWeight={500}
            color="blue"
            mr={2}
            minWidth={'225px'}
          >
            Coming soon - Help shape this feature!
          </Text>
          <Button
            as="a"
            href={FEEDBACK_FORM_URL}
            target="_blank"
            bg="#0071E3"
            color="white"
            _hover={{ bg: '#0071E3', color: 'white' }}
            borderRadius="100px"
            height="28px"
            alignItems="center"
            minWidth={'90px'}
            p="2px 10px 2px 10px"
            gap="6px"
          >
            <Text fontSize="12px" fontWeight="500" whiteSpace="nowrap">
              Share ideas
            </Text>
          </Button>
        </Flex>
      </Wrap>
      <Text fontSize="12px" lineHeight="16px" color="#585858">
        Using AI, intelligently adjust Automatic Save to be personalized to your unique way of
        browsing and your specific active projects.
      </Text>
      {isInternal && (
        <InternalSettingsContent
          guidedAutoSaveFrequencyThreshold={guidedAutoSaveFrequencyThreshold}
          guidedAutoSaveInactivityThreshold={guidedAutoSaveInactivityThreshold}
          onChangeGuidedAutoSaveFrequencyThreshold={handleChangeGuidedAutoSaveFrequencyThreshold}
          onChangeGuidedAutoSaveInactivityThreshold={handleChangeGuidedAutoSaveInactivityThreshold}
        />
      )}
      {(isDemoAccount || isDeveloper) && <AdminSettingsContent />}
    </Box>
  )
}

export default SettingsForm
