import React, { FC, useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { Button, Spinner } from '@chakra-ui/react'
import { FcGoogle } from 'react-icons/fc'
import { useGoogleLogin } from '@react-oauth/google'
import { useNavigate } from 'react-router'
import { APP_NAME, AUTH_REDIRECT_URL, ENABLE_META_PIXEL, META_PIXEL_ID } from '../../../constants'
import AnnouncementsPage from '../../../components/AnnouncementsPage'
import { useAuthContext } from '../../../contexts/AuthContext'

const ExtensionInstalledPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { isAuthenticated } = useAuthContext()

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: AUTH_REDIRECT_URL,
  })

  useEffect(() => {
    if (ENABLE_META_PIXEL) {
      ReactPixel.init(META_PIXEL_ID)
      ReactPixel.track('ViewContent')
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      console.log('User is authenticated on ExtensionInstalledPage')
      setIsLoading(true)
      navigate('/')
    }
  }, [isAuthenticated, navigate])

  const googleLoginButton = (
    <Button
      mb="4"
      as="a"
      style={{
        backgroundColor: 'black',
        borderRadius: '20px',
        borderColor: '#D5D5D5',
        color: 'white',
        fontWeight: 'normal',
        padding: '0 30px',
      }}
      onClick={googleLogin}
      leftIcon={<FcGoogle />}
    >
      Continue with Google
    </Button>
  )

  if (isLoading) {
    return <Spinner size="xl" />
  }

  return (
    <AnnouncementsPage
      heading={`Declutter your browser.`}
      headingMaxWidth={'400px'}
      content={[
        'Too many open tabs can be overwhelming and slow you down.',
        `${APP_NAME} tidies up unused tabs automatically, helping you stay calm, focused, and effective.`,
      ]}
      widget={googleLoginButton}
    />
  )
}

export default ExtensionInstalledPage
