import React, { FC } from 'react'
import { DnDItemSmartSessionPayload } from '../../../models/dnd.types'
import DragIcon from '../../../components/DragIcon'
import { Box, Flex, Heading } from '@chakra-ui/react'
import PutasideTabView from '../PutasideTabList/PutasideTabView'

function emptyFunc(_event: React.MouseEvent<HTMLElement, MouseEvent>): void {
  throw new Error('Function not implemented.')
}

interface Props {
  item: DnDItemSmartSessionPayload
}

const SmartSessionDragPreview: FC<Props> = (props) => {
  const { item } = props
  const { name, pages } = item.session

  return (
    <Box
      position="relative"
      width="272px"
      display="flex"
      flexDirection="column"
      padding="16px 16px 16px 2px"
      borderRadius="12px"
      border="1.5px solid #cfd2ef"
      cursor="grabbing"
      boxSizing="border-box"
      background="#f2f3fe"
      opacity="0.7"
      boxShadow={`0px 4px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)`}
    >
      <Flex
        position="relative"
        width="100%"
        alignItems="center"
        overflow="hidden"
        marginBottom="6px"
      >
        <Box w="12px" mr="6px" className="hover-show-title-icons">
          <DragIcon />
        </Box>
        <Heading
          as="h2"
          color="#000"
          fontSize="14px"
          fontWeight="600"
          wordBreak="break-word"
          lineHeight="22px"
        >
          {name}
        </Heading>
      </Flex>
      <Box>
        {pages.map((page) => {
          return (
            <PutasideTabView
              key={page.id}
              entityType={page.entity_type}
              title={
                page.custom_title && page.custom_title.length > 0
                  ? page.custom_title
                  : page.title ?? 'Untitled Page'
              }
              favIconUrl={page.favicon_url}
              isTitleClickDisabled={true}
              isFocused={false}
              isCheckboxShown={false}
              showTimeString={false}
              showActionIcons={false}
              isDraggingDisabled={true}
              showOpenTabIcon={page.is_open}
              lastAccessDateTime={page.last_access_datetime_utc_iso}
              handleClickFavIcon={emptyFunc}
              handleClickDragIcon={emptyFunc}
              handleClickTitle={emptyFunc}
              handleClickBody={emptyFunc}
              isDraggingThis={false}
              isSelected={false}
              isTabAboveSelected={false}
              isTabBelowSelected={false}
              isSelectionDisabled={false}
              isHoverDisabled={false}
              spacingVariant={'default'}
              isDragIconPlaceholderHidden={false}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default SmartSessionDragPreview
