import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Text, Spinner, Box, Flex, Button, ToastId } from '@chakra-ui/react'
import {
  useGetSmartSessionsWithOpenTabsV2Query,
  useLazyGetSmartSessionsWithOpenTabsV2Query,
} from '../../redux/services/skeema/smart_sessions.endpoints'
import SmartSessionCard from './smartSessions/SmartSessionCard'
import { TablistPageType } from '../../models/tablist_pages.types'
import { useUserContext } from '../../contexts/UserContext'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { useReduxDispatch, useReduxSelector } from '../../redux/baseStore'
import {
  selectNumActiveProjects,
  useCreateProjectMutation,
  useGetActiveProjectsQuery,
} from '../../redux/services/skeema/projects.endpoints'
import { setNewlyCreatedProjectId } from '../../redux/projectsSlice'
import { useGetOpenTabsQuery } from '../../webapp/redux/extension'
import ProjectLimitModal from './projectsSidebar/ProjectLimitModal'
import { useNavigate } from 'react-router-dom'
import { SmartSessionType } from '../../models/smart_sessions.types'
import { setSessions, appendSessions, selectSessionSessions } from '../../redux/sessionSlice'
import { APP_NAME } from '../../constants'
import {
  handleSmartSessionPageSelection,
  removeSelectedSmartSessionPages,
  selectSelectedSmartSessionPages,
} from '../../redux/selectedPagesSlice'
import MultiSelectToolbar from './multiSelectToolbar/MultiSelectToolbar'

function getDateString(date: Date) {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

function groupSessions(sessions: SmartSessionType[]) {
  const now = new Date()
  const todayStartOffset = 3
  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), todayStartOffset)
  const startOfYesterday = new Date(
    startOfToday.getTime() - (24 + todayStartOffset) * 60 * 60 * 1000,
  )

  const grouped = sessions.reduce(
    (acc, s) => {
      const sessionDate = new Date(s.datetime_end_ts * 1000)
      let groupHeader: string

      if (sessionDate >= startOfToday) {
        groupHeader = 'Today'
      } else if (sessionDate >= startOfYesterday) {
        groupHeader = 'Yesterday'
      } else {
        groupHeader = getDateString(sessionDate)
      }

      if (!acc[groupHeader]) {
        acc[groupHeader] = []
      }
      acc[groupHeader].push(s)
      return acc
    },
    {} as Record<string, SmartSessionType[]>,
  )

  return grouped
}

interface Props {
  isSSOnboardingActive: boolean
}

const SmartSessionDashboard: FC<Props> = (props) => {
  const { isSSOnboardingActive } = props
  const navigate = useNavigate()
  const { captureAnalytics } = useUserContext()
  const dispatch = useReduxDispatch()

  const selectedPages = useReduxSelector(selectSelectedSmartSessionPages)
  const isSelectionActive = Object.keys(selectedPages).length > 0

  const [shouldSkipQueryHook, setShouldSkipQueryHook] = useState<boolean>(false)
  const [isProjectLimitModalOpen, setIsProjectLimitModalOpen] = useState<boolean>(false)

  const { projectConfig } = useFeatureFlagContext()
  const numProjects = useReduxSelector(selectNumActiveProjects)
  const maxNumProjects = projectConfig.maxNumProjects
  const isProjectLimitReached = numProjects === undefined || numProjects >= maxNumProjects

  const sessions = useReduxSelector(selectSessionSessions)
  const [currentPageDatetimeEnd, setCurrentPageDatetimeEnd] = useState<number | undefined>(
    undefined,
  )

  const toastIdRef = useRef<ToastId | undefined>(undefined)

  const { data: openTabPages, isLoading: isOpenTabsLoading } = useGetOpenTabsQuery(undefined, {
    pollingInterval: 1000,
  })
  // TODO:
  // 1. When page is reloaded, this query will be triggered twice once with empty openTabs and once with actual openTabs
  // 2. The way we handle query refetch with the shouldSkipQueryHook is awkward
  const { data: sessionsQueryData } = useGetSmartSessionsWithOpenTabsV2Query(
    {
      openTabs: openTabPages,
      from_ts: currentPageDatetimeEnd,
    },
    {
      skip: shouldSkipQueryHook || isOpenTabsLoading,
    },
  )

  const sessionsGroupedByDate = useMemo(() => {
    if (!sessions) {
      return undefined
    }
    return groupSessions(sessions)
  }, [sessions])

  useEffect(() => {
    if (sessionsQueryData) {
      dispatch(setSessions(sessionsQueryData.sessions))
      if (sessionsQueryData.sessions.length > 0) {
        setCurrentPageDatetimeEnd(
          sessionsQueryData.sessions[sessionsQueryData.sessions.length - 1].datetime_end_ts,
        )
      }
    }
  }, [dispatch, sessionsQueryData])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (window.document.hidden) {
        setShouldSkipQueryHook(false)
      }
    }
    window.document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      window.document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [captureAnalytics, dispatch])

  const { data: existingProjects } = useGetActiveProjectsQuery(undefined)
  const [createProjectMutation] = useCreateProjectMutation()

  const [didShowOlderSessions, setDidShowOlderSessions] = useState<boolean>(false)
  const [triggerSmartSessionsQuery, manualSmartSessionsQueryResult] =
    useLazyGetSmartSessionsWithOpenTabsV2Query({ refetchOnFocus: false })
  const { data: manualSmartSessionsData, error: manualSmartSessionsError } =
    manualSmartSessionsQueryResult
  const doMorePagesExist = manualSmartSessionsData
    ? manualSmartSessionsData.do_more_pages_exist
    : sessionsQueryData?.do_more_pages_exist

  useEffect(() => {
    if (manualSmartSessionsError) {
      console.error('Error with manualSmartSessionsQueryResult', manualSmartSessionsError)
      return
    }

    if (!manualSmartSessionsData) {
      return
    }

    if (manualSmartSessionsData.sessions.length > 0) {
      dispatch(appendSessions(manualSmartSessionsData.sessions))
      if (manualSmartSessionsData.sessions.length > 0) {
        setCurrentPageDatetimeEnd(
          manualSmartSessionsData.sessions[manualSmartSessionsData.sessions.length - 1]
            .datetime_end_ts,
        )
      }
    }
  }, [dispatch, manualSmartSessionsData, manualSmartSessionsError])

  const handleCreateProject = useCallback(
    async (title: string, tablistPages: TablistPageType[]) => {
      if (isProjectLimitReached) {
        return
      }

      const project = await createProjectMutation({
        title,
        tablistPages,
        existingProjects: existingProjects === undefined ? [] : existingProjects,
        titlePrefix: 'Folder',
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      if (project) {
        dispatch(setNewlyCreatedProjectId(project.id))
      }
    },
    [createProjectMutation, dispatch, existingProjects, isProjectLimitReached],
  )

  const handleCardPageSelection = useCallback(
    (params: {
      clickedSessionId: string
      clickedPageId: string
      isShiftKey: boolean
      isCtrlOrCmdKey: boolean
      isCurrentlySelected: boolean
      extraAnalyticsProps?: Record<string, unknown>
    }): void => {
      const { clickedSessionId, clickedPageId, isShiftKey, isCtrlOrCmdKey, extraAnalyticsProps } =
        params
      if (!sessionsGroupedByDate) {
        return
      }

      const flatSessions = Object.values(sessionsGroupedByDate).flat()
      const itemsWithIds = flatSessions
        .map((s) => {
          return s.pages.map((p) => ({ ...p, id: `${s.id}-${p.id}` }))
        })
        .flat()
      const clickedItemId = `${clickedSessionId}-${clickedPageId}`

      dispatch(
        handleSmartSessionPageSelection({
          pages: itemsWithIds,
          clickedItemId,
          isShiftKey,
          isCtrlOrCmdKey,
        }),
      )

      captureAnalytics('smart_session_dashboard:page_select', {
        clickedSessionId,
        clickedPageId,
        clickedItemId,
        numSelectedPages: Object.keys(selectedPages).length,
        isCurrentlySelected: clickedItemId in selectedPages,
        isShiftKey,
        isCtrlOrCmdKey,
        ...(extraAnalyticsProps ?? {}),
      })
    },
    [sessionsGroupedByDate, dispatch, captureAnalytics, selectedPages],
  )

  const handleRemoveCardPageSelection = useCallback(
    (params: { pageId: string; sessionId: string }) => {
      const { pageId, sessionId } = params
      const clickedItemId = `${sessionId}-${pageId}`
      dispatch(removeSelectedSmartSessionPages([clickedItemId]))

      captureAnalytics('smart_session_dashboard:page_selection_remove_side_effect', {
        pageId,
        sessionId,
        clickedItemId,
        numSelectedPages: Object.keys(selectedPages).length,
        isCurrentlySelected: clickedItemId in selectedPages,
      })
    },
    [dispatch, captureAnalytics, selectedPages],
  )

  const onClickShowOlderSessions = useCallback(() => {
    const to_ts = currentPageDatetimeEnd ?? Math.floor(Date.now() / 1000) - 24 * 60 * 60
    captureAnalytics('smart_session_dashboard:show_older_sessions_click', {
      to_ts: to_ts,
    })
    setShouldSkipQueryHook(true)
    triggerSmartSessionsQuery({
      openTabs: openTabPages,
      to_ts,
    })
    setDidShowOlderSessions(true)
  }, [captureAnalytics, currentPageDatetimeEnd, openTabPages, triggerSmartSessionsQuery])

  const handleProjectLimitError = useCallback(() => {
    captureAnalytics('smart_session_dashboard:project_limit_modal_show')
    setIsProjectLimitModalOpen(true)
  }, [captureAnalytics])

  const handleDismissProjectLimitModal = () => {
    captureAnalytics('smart_session_dashboard:project_limit_modal_dismiss_click')
    setIsProjectLimitModalOpen(false)
  }

  const handleSubmitProjectLimitModal = () => {
    captureAnalytics('smart_session_dashboard:project_limit_modal_upgrade_click')
    setIsProjectLimitModalOpen(false)
    navigate('/#pricing')
  }

  return (
    <Box p="0px 16px 24px 8px">
      {!sessionsGroupedByDate && (
        <div className="flex-center" style={{ width: '100%', margin: '32px' }}>
          <Spinner color="blue.500" size="lg" speed="1s" />
        </div>
      )}
      {sessionsGroupedByDate &&
        Object.keys(sessionsGroupedByDate).length === 0 &&
        !doMorePagesExist && (
          <div className="flex-center" style={{ width: '100%' }}>
            <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
              {`${APP_NAME} uses AI to capture sessions for you. Keep browsing and they'll appear here soon!`}
            </Text>
          </div>
        )}
      {sessionsGroupedByDate &&
        Object.keys(sessionsGroupedByDate).length === 0 &&
        doMorePagesExist && (
          <div className="flex-center" style={{ width: '100%' }}>
            <Text
              textAlign={'center'}
              margin={4}
              fontSize="14px"
              color="#a7a7a7"
              whiteSpace="pre-line"
            >
              {`You're all caught up on your recent Sessions 🥳 \nKeep browsing and we'll continue to organize your sessions!`}
            </Text>
          </div>
        )}

      <MultiSelectToolbar />

      <Flex flexDirection="column" gap="8px" mt={isSelectionActive ? '12px' : undefined}>
        {sessionsGroupedByDate &&
          Object.keys(sessionsGroupedByDate).length > 0 &&
          Object.keys(sessionsGroupedByDate).map((key) => {
            const group = sessionsGroupedByDate[key]
            return (
              <React.Fragment key={key}>
                <Text mt="4px" ml="6px" fontSize="12px" fontWeight={500} color="#A7A7A7">
                  {key}
                </Text>
                <Flex flexDirection="column" gap="12px">
                  {group.map((session, idx) => (
                    <SmartSessionCard
                      key={`${session.name}${idx}`}
                      session={session}
                      selectedPages={selectedPages}
                      isSSOnboardingActive={isSSOnboardingActive}
                      handleCreateProject={handleCreateProject}
                      handleCardPageSelection={handleCardPageSelection}
                      handleRemoveCardPageSelection={handleRemoveCardPageSelection}
                      isProjectLimitReached={isProjectLimitReached}
                      handleProjectLimitError={handleProjectLimitError}
                      shouldHideCardActionIcons={isSelectionActive}
                      toastIdRef={toastIdRef}
                    />
                  ))}
                </Flex>
              </React.Fragment>
            )
          })}

        {doMorePagesExist !== undefined && (
          <Flex w="100%" alignItems="center" justifyContent="flex-end">
            {doMorePagesExist && (
              <Button
                key="extra"
                size="sm"
                fontSize={12}
                fontWeight={500}
                borderRadius={'16px'}
                h="32px"
                minH="32px"
                w="100%"
                px={2}
                pointerEvents={isSSOnboardingActive ? 'none' : 'auto'}
                onClick={onClickShowOlderSessions}
                bg="#F6F6F6"
                color="#585858"
                _hover={{ bg: '#EBEBEB' }}
                isLoading={manualSmartSessionsQueryResult.isLoading}
              >
                {`Show older`}
              </Button>
            )}
            {!doMorePagesExist && sessions && sessions.length > 0 && didShowOlderSessions && (
              <Text
                fontSize={12}
                fontWeight={500}
                mt="8px"
                w="100%"
                px={2}
                color="#A7A7A7"
                textAlign="center"
              >
                {`You've reached the end of sessions`}
              </Text>
            )}
          </Flex>
        )}
      </Flex>

      <ProjectLimitModal
        isOpen={isProjectLimitModalOpen}
        onCancel={handleDismissProjectLimitModal}
        onSubmit={handleSubmitProjectLimitModal}
      />
    </Box>
  )
}

export default SmartSessionDashboard
