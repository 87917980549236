import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useUserContext } from '../../../contexts/UserContext'
import { setIsProjectPageDragging } from '../../../redux/dndSlice'
import { useDrag, useDrop } from 'react-dnd'
import {
  DND_ITEM_ENUM,
  DnDItemPayload,
  DnDItemProjectPagePayload,
  DnDItemProjectPagesPayload,
} from '../../../models/dnd.types'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { ProjectPageType } from '../../../models/saved_sessions.types'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../../models/tablist_pages.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { shouldOpenInNewTab } from '../../../utils/utils'
import PutasideTabView, {
  PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM,
} from '../PutasideTabList/PutasideTabView'
import { MoveProjectPageInfoType } from '../moveItemMenu/moveItemMenu.types'
import ProjectDetailPageEditableTitle from './ProjectDetailPageEditableTitle'
import webappStore from '../../../webapp/redux/webappStore'
import { getEmptyImage } from 'react-dnd-html5-backend'

interface PropTypes {
  projectId: string
  page: ProjectPageType
  numProjectPages: number
  maxNumProjectPages: number
  index: number
  handleDropProjectPage: (params: {
    sourceProjectId: string
    index: number
    page: ProjectPageType
  }) => Promise<void>
  handleAddTabsToProject: (params: {
    tablistPages: TablistPageType[]
    index?: number
    loggingProps?: Record<string, unknown>
  }) => Promise<void>
  handleOpenMoveModal: (params: { pageInfo: Omit<MoveProjectPageInfoType, 'project'> }) => void
  deleteProjectPage: (params: { page: ProjectPageType }) => Promise<void>
  renameProjectPage: (params: { page: ProjectPageType; customTitle: string }) => Promise<void>
  handleProjectPageLimitError: () => void
  onSelected?: (params: {
    clickedItemId: string
    isShiftKey: boolean
    isCtrlOrCmdKey: boolean
    isCurrentlySelected: boolean
    extraAnalyticsProps?: Record<string, unknown>
  }) => void
  removeSelection?: (params: { pageId: string }) => void
  isSelected?: boolean
  isTabAboveSelected?: boolean
  isTabBelowSelected?: boolean
  isDraggingDisabled?: boolean
  shouldForceHighlightTop?: boolean
  shouldForceHighlightBottom?: boolean
  activeRenamePageId: string | null
  shouldHideRenameIcon?: boolean
  setActiveRenamePageId: (id: string | null) => void
}

const ProjectDetailPageController: FC<PropTypes> = (props) => {
  const {
    projectId,
    page,
    index,
    deleteProjectPage,
    renameProjectPage,
    numProjectPages,
    maxNumProjectPages,
    handleDropProjectPage,
    handleAddTabsToProject,
    handleProjectPageLimitError,
    handleOpenMoveModal,
    onSelected,
    removeSelection,
    isSelected = false,
    isTabAboveSelected = false,
    isTabBelowSelected = false,
    shouldForceHighlightTop = false,
    shouldForceHighlightBottom = false,
    isDraggingDisabled = false,
    activeRenamePageId,
    setActiveRenamePageId,
    shouldHideRenameIcon = false,
  } = props
  const { captureAnalytics } = useUserContext()
  const dispatch = useReduxDispatch()
  const isProjectPageDragging = useReduxSelector((state) => state.dnd.isProjectPageDragging)

  const [isRenameActive, setIsRenameActive] = useState(false)

  useEffect(() => {
    if (activeRenamePageId !== page.id) {
      setIsRenameActive(false)
    }
  }, [activeRenamePageId, page.id])

  const handleClickTitle = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
        urls: [page.url],
        shouldActivate: !shouldOpenInNewTab(event),
      })
      captureAnalytics('project_detail_dashboard:page_title_click', { projectId, ...page })
    },
    [captureAnalytics, projectId, page],
  )

  const handleClickEditIcon = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      captureAnalytics('project_detail_dashboard:page_edit_click', { projectId, ...page })

      setActiveRenamePageId(page.id)
      setIsRenameActive(true)
      isSelected && removeSelection && removeSelection({ pageId: page.id })
    },
    [captureAnalytics, projectId, page, setActiveRenamePageId, removeSelection, isSelected],
  )

  const handleCancelRename = useCallback(() => {
    setIsRenameActive(false)
    setActiveRenamePageId(null)
  }, [setActiveRenamePageId])

  const handleSaveRename = useCallback(
    (newTitle: string) => {
      if (newTitle === '' || page.title === newTitle) {
        handleCancelRename()
        return
      }

      renameProjectPage({ page, customTitle: newTitle })
      setIsRenameActive(false)
      captureAnalytics('project_detail_dashboard:page_rename_submit_click', {
        projectId,
        pageId: page.id,
        oldTitle: page.title,
        newTitle,
      })
    },
    [captureAnalytics, handleCancelRename, page, projectId, renameProjectPage],
  )

  const handleClickDeleteIcon = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      captureAnalytics('project_detail_dashboard:page_delete_click', { projectId, ...page })
      deleteProjectPage({ page })
      isSelected && removeSelection && removeSelection({ pageId: page.id })
    },
    [captureAnalytics, projectId, page, deleteProjectPage, removeSelection, isSelected],
  )

  const handleClickMoveIcon = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      captureAnalytics('project_detail_dashboard:page_move_click', { projectId, ...page })
      handleOpenMoveModal({ pageInfo: { type: 'PROJECT_PAGE', page } })
      isSelected && removeSelection && removeSelection({ pageId: page.id })
    },
    [captureAnalytics, projectId, page, handleOpenMoveModal, removeSelection, isSelected],
  )

  const handleSelect = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      onSelected &&
        onSelected({
          clickedItemId: page.id,
          isCurrentlySelected: isSelected,
          isShiftKey: event.shiftKey,
          isCtrlOrCmdKey: event.ctrlKey || event.metaKey,
        })
    },
    [onSelected, page.id, isSelected],
  )

  const handleClickBody = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      captureAnalytics(`project_detail_dashboard:page_body_click`, {
        title: page.title,
        url: page.url,
        index,
        numProjectPages,
        isCurrentlySelected: isSelected,
        isHandleSelectBound: true,
      })

      handleSelect(event)
    },
    [handleSelect, captureAnalytics, page.title, page.url, index, numProjectPages, isSelected],
  )

  const handleClickFavIcon = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      captureAnalytics(`project_detail_dashboard:page_fav_icon_click`, {
        title: page.title,
        url: page.url,
        index,
        numProjectPages,
        isCurrentlySelected: isSelected,
        isHandleSelectBound: true,
      })

      handleSelect(event)
    },
    [handleSelect, captureAnalytics, page.title, page.url, index, numProjectPages, isSelected],
  )

  const handleClickDragIcon = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      captureAnalytics(`project_detail_dashboard:page_drag_icon_click`, {
        title: page.title,
        url: page.url,
        index,
        numProjectPages,
        isCurrentlySelected: isSelected,
        isHandleSelectBound: true,
      })

      handleSelect(event)
    },
    [handleSelect, captureAnalytics, page.title, page.url, index, numProjectPages, isSelected],
  )

  const [{ isDraggingThis }, connectDragSource, connectDragPreview] = useDrag(
    () => ({
      type: DND_ITEM_ENUM.PROJECT_PAGES,
      collect: (monitor) => ({
        isDraggingThis: monitor.isDragging(),
      }),
      canDrag: () => !isDraggingDisabled,
      end: () => dispatch(setIsProjectPageDragging(false)),
      item: () => {
        dispatch(setIsProjectPageDragging(true))

        const payloads: DnDItemProjectPagePayload[] = []

        if (isSelected) {
          payloads.push(
            ...Object.values(webappStore.getState().selectedPages.selectedProjectPages).map(
              (p): DnDItemProjectPagePayload => ({
                type: DND_ITEM_ENUM.PROJECT_PAGE,
                page: p,
                projectId,
                index: p.order,
              }),
            ),
          )
        } else {
          payloads.push({
            type: DND_ITEM_ENUM.PROJECT_PAGE,
            page,
            projectId,
            index,
          })
        }

        payloads.sort((a, b) => a.index - b.index)

        const payload: DnDItemProjectPagesPayload = {
          type: DND_ITEM_ENUM.PROJECT_PAGES,
          payloads,
        }

        return payload
      },
    }),
    [projectId, page, dispatch, isDraggingDisabled, index, isSelected],
  )

  useEffect(() => {
    connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [connectDragPreview])

  const dropSourceRef = useRef<HTMLDivElement | null>(null)
  const [dragOverLocation, setDragOverLocation] = useState<'top' | 'bottom' | undefined>(undefined)
  const dragOverLocationRef = useRef<'top' | 'bottom' | undefined>(undefined)
  useEffect(() => {
    dragOverLocationRef.current = dragOverLocation
  }, [dragOverLocation])

  const [{ isDraggingOverThis }, connectDropTarget] = useDrop(
    () => ({
      accept: [
        DND_ITEM_ENUM.PROJECT_PAGE,
        DND_ITEM_ENUM.PROJECT_PAGES,
        DND_ITEM_ENUM.TABLIST_PAGE,
        DND_ITEM_ENUM.TABLIST_PAGES,
        DND_ITEM_ENUM.SMART_SESSION,
      ],
      collect: (monitor) => ({
        isDraggingOverThis: monitor.isOver(),
      }),
      canDrop: (payload: DnDItemPayload) => {
        if (
          payload.type === DND_ITEM_ENUM.PROJECT_PAGE ||
          (payload.type === DND_ITEM_ENUM.PROJECT_PAGES && payload.payloads.length === 1)
        ) {
          const pl = payload.type === DND_ITEM_ENUM.PROJECT_PAGE ? payload : payload.payloads[0]
          if (pl.page.id === page.id) {
            return false
          }
          if (pl.projectId === projectId) {
            const isSameLocation =
              (pl.index === index - 1 && dragOverLocation === 'top') ||
              (pl.index === index + 1 && dragOverLocation === 'bottom')
            if (isSameLocation) {
              return false
            }
          }
          return true
        } else if (payload.type === DND_ITEM_ENUM.PROJECT_PAGES && payload.payloads.length > 1) {
          //Disabling multiselect reordering for now
          return false
        } else if (
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES
        ) {
          return true
        } else if (payload.type === DND_ITEM_ENUM.SMART_SESSION) {
          return true
        }

        return false
      },
      hover: (payload: DnDItemPayload, monitor) => {
        if (
          (payload.type === DND_ITEM_ENUM.PROJECT_PAGE && payload.page.id === page.id) ||
          (payload.type === DND_ITEM_ENUM.PROJECT_PAGES &&
            payload.payloads.length === 1 &&
            payload.payloads[0].page.id === page.id)
        ) {
          return
        }

        if (payload.type === DND_ITEM_ENUM.PROJECT_PAGES && payload.payloads.length > 1) {
          //Disabling multiselect reordering for now
          return
        }

        if (isSelected) {
          return
        }

        const { y } = monitor.getClientOffset() ?? {}
        const { height, top } = dropSourceRef.current?.getBoundingClientRect() ?? {}
        const middleY = height && top ? top + height / 2 : undefined

        if (middleY === undefined || y === undefined) {
          setDragOverLocation(undefined)
          return
        }

        const newDragLocation = middleY > y ? 'top' : 'bottom'

        const isSameProject =
          (payload.type === DND_ITEM_ENUM.PROJECT_PAGE && payload.projectId === projectId) ||
          (payload.type === DND_ITEM_ENUM.PROJECT_PAGES &&
            payload.payloads.length > 0 &&
            payload.payloads[0].projectId === projectId)

        if (isSameProject) {
          const pl = payload.type === DND_ITEM_ENUM.PROJECT_PAGE ? payload : payload.payloads[0]
          const isSameLocation =
            (pl.index === index - 1 && newDragLocation === 'top') ||
            (pl.index === index + 1 && newDragLocation === 'bottom')
          const isOnlyOneItem =
            payload.type === DND_ITEM_ENUM.PROJECT_PAGE ||
            (payload.type === DND_ITEM_ENUM.PROJECT_PAGES && payload.payloads.length === 1)
          if (isSameLocation && isOnlyOneItem) {
            setDragOverLocation(undefined)
            return
          }
        }

        setDragOverLocation(newDragLocation)
      },
      drop: async (payload: DnDItemPayload, monitor) => {
        if (monitor.didDrop()) {
          //Another drop target received the drop event already
          return { status: 'DID_DROP' }
        }

        const isDragItemFromThisProject =
          (payload.type === DND_ITEM_ENUM.PROJECT_PAGE && payload.projectId === projectId) ||
          (payload.type === DND_ITEM_ENUM.PROJECT_PAGES &&
            payload.payloads.length > 0 &&
            payload.payloads[0].projectId === projectId)
        const numAddPages = isDragItemFromThisProject
          ? 0
          : payload.type === DND_ITEM_ENUM.SMART_SESSION
            ? payload.session.pages.length
            : payload.type === DND_ITEM_ENUM.TABLIST_PAGES ||
                payload.type === DND_ITEM_ENUM.PROJECT_PAGES
              ? payload.payloads.length
              : 1
        const newNumPages = numProjectPages + numAddPages
        const shouldShowPageLimitError = newNumPages > maxNumProjectPages

        if (shouldShowPageLimitError) {
          handleProjectPageLimitError()
          return { status: 'ERROR: Project page limit reached' }
        }

        let dropIndex = dragOverLocationRef.current === 'top' ? index : index + 1

        if (
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES
        ) {
          const payloads =
            payload.type === DND_ITEM_ENUM.TABLIST_PAGES ? payload.payloads : [payload]
          const tablistPages = payloads.map((pl) => pl.page)

          await handleAddTabsToProject({
            tablistPages,
            index: dropIndex,
          })

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.SMART_SESSION) {
          const newPages = [...payload.session.pages]

          await handleAddTabsToProject({
            tablistPages: newPages,
            index: dropIndex,
            loggingProps: {
              smartSession: payload.session,
            },
          })

          return { status: 'SUCCESS' }
        } else if (
          payload.type === DND_ITEM_ENUM.PROJECT_PAGE ||
          payload.type === DND_ITEM_ENUM.PROJECT_PAGES
        ) {
          const payloads =
            payload.type === DND_ITEM_ENUM.PROJECT_PAGES ? payload.payloads : [payload]

          //This does not always work correctly for multiple page reordering, but that case is disabled above
          //TODO: Create endpoint to handle multiple page reordering instead of doing this
          for (const pl of payloads) {
            await handleDropProjectPage({
              sourceProjectId: pl.projectId,
              index: dropIndex,
              page: pl.page,
            })
            dropIndex++
          }

          return { status: 'SUCCESS' }
        }

        return { status: 'ERROR' }
      },
    }),
    [
      handleAddTabsToProject,
      handleDropProjectPage,
      handleProjectPageLimitError,
      numProjectPages,
      maxNumProjectPages,
      projectId,
      page,
      index,
      dragOverLocation,
      isSelected,
    ],
  )

  useEffect(() => {
    if (!isDraggingOverThis) {
      setDragOverLocation(undefined)
    }
  }, [isDraggingOverThis])

  const connectDnd = useCallback(
    (node: HTMLDivElement | null) => {
      dropSourceRef.current = node
      connectDragSource(connectDropTarget(dropSourceRef))
    },
    [connectDragSource, connectDropTarget],
  )

  return isRenameActive ? (
    <ProjectDetailPageEditableTitle
      title={page.custom_title && page.custom_title.length > 0 ? page.custom_title : page.title}
      favIconUrl={page.favicon_url}
      handleSaveRename={handleSaveRename}
      handleCancelRename={handleCancelRename}
    />
  ) : (
    <PutasideTabView
      title={page.custom_title && page.custom_title.length > 0 ? page.custom_title : page.title}
      favIconUrl={page.favicon_url}
      isDraggingThis={isDraggingThis || (isProjectPageDragging && isSelected)}
      isDraggingDisabled={isDraggingDisabled}
      dragOverLocation={dragOverLocation}
      shouldForceHighlightTop={shouldForceHighlightTop}
      shouldForceHighlightBottom={shouldForceHighlightBottom}
      connectDnD={!isDraggingDisabled ? connectDnd : undefined}
      handleClickFavIcon={handleClickFavIcon}
      handleClickDragIcon={handleClickDragIcon}
      handleClickDeleteIcon={handleClickDeleteIcon}
      shouldShowDeleteIcon={true}
      handleClickMoveIcon={handleClickMoveIcon}
      handleClickEditIcon={handleClickEditIcon}
      entityType={TABLIST_PAGE_ENUM.PROJECT_PAGE}
      lastAccessDateTime={page.last_access_datetime ?? ''}
      handleClickTitle={handleClickTitle}
      handleClickBody={handleClickBody}
      isSelected={isSelected}
      isTabAboveSelected={isTabAboveSelected}
      isTabBelowSelected={isTabBelowSelected}
      isCheckboxShown={false}
      isSelectionDisabled={false}
      isHoverDisabled={false}
      isTitleClickDisabled={false}
      isTitleEditable={!shouldHideRenameIcon}
      showTimeString={false}
      showActionIcons={true}
      isFocused={false}
      isDragIconPlaceholderHidden={false}
      spacingVariant={PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM.DEFAULT}
      titleToolTip={
        page.custom_title ? (
          <span style={{ fontSize: '12px' }}>
            Original title: {page.title}
            <br /> <br />
            URL: {page.url}
          </span>
        ) : (
          page.url
        )
      }
      moveIconTooltip={'Move'}
      deleteIconToolTip={'Remove'}
    />
  )
}

export default ProjectDetailPageController
