import React, { FC, useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { css, styled } from 'styled-components'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { useDrop } from 'react-dnd'
import { DND_ITEM_ENUM, DnDItemPayload } from '../../../models/dnd.types'
import { cx } from '@emotion/css'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { clearSelectedPages } from '../../../redux/selectedPagesSlice'
import { Text, Icon, Flex } from '@chakra-ui/react'

const Container = styled(Flex)<{
  $isDraggingOver: boolean
}>`
  &:hover,
  &.force-hover {
    background-color: #585858;

    ${({ $isDraggingOver }) =>
      $isDraggingOver
        ? css`
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%230071e3' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");
            background-color: rgba(46, 69, 92);
          `
        : ''}

    .info-label {
      max-height: 50px;
    }
  }
`
interface Props {
  handleActivateFolderCreationMode: (params: {
    tablistPages: TablistPageType[]
    title?: string
    loggingProps?: Record<string, unknown>
  }) => void
  isProjectLimitReached: boolean
  isDisabled?: boolean
  handleProjectLimitError: () => void
}

const CreateProjectItem: FC<Props> = (props) => {
  const {
    handleActivateFolderCreationMode,
    isProjectLimitReached,
    isDisabled = false,
    handleProjectLimitError,
  } = props
  const dispatch = useReduxDispatch()
  const isTablistPageDragging = useReduxSelector((state) => state.dnd.isTablistPageDragging)
  const isSmartSessionDragging = useReduxSelector((state) => state.dnd.isSmartSessionDragging)
  const shouldForceHoverState = isTablistPageDragging || isSmartSessionDragging
  const [isActiveState, setIsActiveState] = useState(false)

  useEffect(() => {
    if (isActiveState) {
      setTimeout(() => {
        setIsActiveState(false)
      }, 500)
    }
  }, [isActiveState])

  const handleClickContainer = () => {
    if (isProjectLimitReached) {
      handleProjectLimitError()
    } else {
      handleActivateFolderCreationMode({ tablistPages: [] })
    }
  }

  const [{ isDraggingOver }, connectDropTarget] = useDrop(
    () => ({
      accept: [
        DND_ITEM_ENUM.TABLIST_PAGE,
        DND_ITEM_ENUM.TABLIST_PAGES,
        DND_ITEM_ENUM.SMART_SESSION,
      ],
      collect: (monitor) => ({
        isDraggingOver: monitor.isOver(),
      }),
      canDrop: (_payload: DnDItemPayload) => {
        return true
      },
      drop: (payload: DnDItemPayload) => {
        if (isProjectLimitReached) {
          handleProjectLimitError()
          return { status: 'ERROR: Project limit reached' }
        }

        if (
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES
        ) {
          const payloads =
            payload.type === DND_ITEM_ENUM.TABLIST_PAGES ? payload.payloads : [payload]
          const tablistPages = payloads.map((pl) => pl.page)
          handleActivateFolderCreationMode({ tablistPages })
          dispatch(clearSelectedPages())

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.SMART_SESSION) {
          const tablistPages = [...payload.session.pages]
          const title = payload.session.name

          handleActivateFolderCreationMode({
            tablistPages,
            title,
            loggingProps: {
              session: payload.session,
            },
          })
          return { status: 'SUCCESS' }
        }

        return { status: 'ERROR' }
      },
    }),
    [handleActivateFolderCreationMode, handleProjectLimitError, isProjectLimitReached, dispatch],
  )

  return (
    <Container
      className={cx({ 'force-hover': shouldForceHoverState })}
      ref={connectDropTarget}
      width="calc(100% - 12px)"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor="#000"
      borderRadius="14px"
      padding="16px"
      margin="2px 6px 16px 6px"
      cursor="pointer"
      transition="border-color 0.2s, background-color 0.2s"
      pointerEvents={isDisabled ? 'none' : 'auto'}
      opacity={isDisabled ? 0.4 : 1}
      $isDraggingOver={isDraggingOver}
      onClick={handleClickContainer}
    >
      <Flex width="100%" alignItems="center">
        <Icon
          as={MdAdd}
          minHeight="18px"
          minWidth="18px"
          height="24px"
          width="24px"
          marginRight="8px"
          color="#fff"
        />
        <Text
          as="h2"
          color="#fff"
          fontSize="14px"
          fontWeight="600"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          lineHeight="22px"
        >
          New folder
        </Text>
      </Flex>
      <Flex
        className="info-label"
        width="100%"
        alignItems="center"
        overflow="hidden"
        maxHeight="0px"
        transition="max-height 0.3s"
      >
        <Text
          as="p"
          marginLeft="34px"
          marginTop="8px"
          fontSize="12px"
          fontWeight="500"
          lineHeight="16px"
          overflow="hidden"
          color="#e0e0e0"
        >
          {`Drop a tab here or into a folder`}
        </Text>
      </Flex>
    </Container>
  )
}

export default CreateProjectItem
