export enum CHROME_STORAGE_LOCAL_KEYS {
  AuthToken = 'AuthToken',
  Notifications = 'Notifications',
  IsSidePanelOnLeft = 'IsSidePanelOnLeft',
  Options = 'Options',
  FeatureFlagValues = 'FeatureFlagValues',
  UserInfo = 'UserInfo',
  TabRecords = 'TabRecords',
  IsTabRecordsInitialized = 'IsTabRecordsInitialized',
  TabRecordsLastUpdateTime = 'TabRecordsLastUpdateTime',
  WindowRecord = 'WindowRecord',
  IsAutoPutawayDisabled = 'IsAutoPutawayDisabled',
  DiagnosticRecords = 'DiagnosticRecords',
  PinnedTabIds = 'PinnedTabIds',
  DiagnosticLogs = 'DiagnosticLogs',
  WindowSessions = 'WindowSessions',
  IsOnboardingCompleted = 'IsOnboardingCompleted',
  LastActiveTime = 'LastActiveTime',
  LastGuidedPutawayTime = 'LastGuidedPutawayTime',
  HighlightedPageIds = 'HighlightedPageIds',
}

export const CHROME_STORAGE_LOCAL_DYNAMIC_KEYS = {
  TabRecord: (id: string) => `Tab_${id}`,
  IsSidePanelOpen: (windowId: string) => `IsSidePanelOpen_${windowId}`,
}

export const CHROME_STORAGE_LOCAL_DEFAULT_KEYS = {
  [CHROME_STORAGE_LOCAL_KEYS.AuthToken]: undefined,
  [CHROME_STORAGE_LOCAL_KEYS.Notifications]: undefined,
  [CHROME_STORAGE_LOCAL_KEYS.IsAutoPutawayDisabled]: false,
}

export enum CHROME_STORAGE_SYNC_KEYS {
  IsFloatingRibbonDisabled = 'IsFloatingRibbonDisabled',
  FloatingRibbonBlacklistedUrls = 'FloatingRibbonBlacklistedUrls',
  ExtensionSettings = 'ExtensionSettings',
}

export const CHROME_STORAGE_SYNC_DEFAULT_KEYS = {
  [CHROME_STORAGE_SYNC_KEYS.IsFloatingRibbonDisabled]: false,
  [CHROME_STORAGE_SYNC_KEYS.FloatingRibbonBlacklistedUrls]: [
    'https://www.disneyplus.com/',
    'https://www.netflix.com/',
    'https://www.youtube.com/',
    'https://www.hulu.com/',
  ],
}

// export enum EXTENSION_WINDOW_LOCAL_STORAGE_KEYS {}

export enum NEWTAB_CACHE_LOCAL_STORAGE_KEYS {
  Projects = 'Projects',
  SavedPages = 'SavedPages',
}

export enum EXTENSION_SESSION_STORAGE_KEYS {
  SkeemaUserSession = 'SkeemaUserSession',
}

export const CHROME_STORAGE_SESSION_DYNAMIC_KEYS = {
  IsSidePanelOpen: (windowId: string) => `IsSidePanelOpen_${windowId}`,
}

export enum EXTENSION_SETTINGS_KEYS {
  OVERWHELM_THRESHOLD = 'overwhelmThreshold',
  STALE_THRESHOLD_BEFORE_OVERWHELM = 'staleThresholdBeforeOverwhelm',
  STALE_THRESHOLD_AFTER_OVERWHELM = 'staleThresholdAfterOverwhelm',
  IS_GUIDED_AUTO_SAVE_ENABLED = 'isGuidedAutoSaveEnabled',
  GUIDED_AUTO_SAVE_INACTIVITY_THRESHOLD = 'guidedAutoSaveInactivityThreshold',
  GUIDED_AUTO_SAVE_FREQUENCY_THRESHOLD = 'guidedAutoSaveFrequencyThreshold',
  DISABLE_AUTO_SAVE_UNTIL = 'disableAutoSaveUntil',
  IS_NEW_TAB_PAGE_OVERRIDE_ENABLED = 'isNewTabPageOverrideEnabled',
}

export type ExtensionSettingsType = {
  [EXTENSION_SETTINGS_KEYS.OVERWHELM_THRESHOLD]: number
  [EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_BEFORE_OVERWHELM]: number
  [EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM]: number
  [EXTENSION_SETTINGS_KEYS.IS_GUIDED_AUTO_SAVE_ENABLED]: boolean
  [EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_INACTIVITY_THRESHOLD]: number
  [EXTENSION_SETTINGS_KEYS.GUIDED_AUTO_SAVE_FREQUENCY_THRESHOLD]: number
  [EXTENSION_SETTINGS_KEYS.DISABLE_AUTO_SAVE_UNTIL]: number
  [EXTENSION_SETTINGS_KEYS.IS_NEW_TAB_PAGE_OVERRIDE_ENABLED]: boolean
}
