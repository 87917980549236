import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  Input,
  Button,
  Box,
  Wrap,
  WrapItem,
  Text,
  Icon,
  Flex,
  ButtonGroup,
  Tooltip,
  Spinner,
} from '@chakra-ui/react'
import {
  useCreateProjectMutation,
  useGetActiveProjectsQuery,
  useLazyGetProjectRecommendationsQuery,
} from '../../../redux/services/skeema/projects.endpoints'
import { TablistPageType } from '../../../models/tablist_pages.types'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { HiSparkles } from 'react-icons/hi'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import {
  addPendingFolderCreationPages,
  cancelFolderCreationMode,
  selectPendingFolderCreationPages,
  selectPendingFolderCreationTitle,
  selectSuggestedFolderCreationPages,
  selectSuggestedFolderCreationTitles,
  setPendingFolderCreationTitle,
  setSuggestedFolderCreationPages,
  setSuggestedFolderCreationTitles,
} from '../../../redux/projectsSlice'
import { MdHelpOutline } from 'react-icons/md'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { useUserContext } from '../../../contexts/UserContext'
import { ProjectType } from '../../../models/saved_sessions.types'
import { useNavigate } from 'react-router-dom'
import { DnDItemPayload } from '../../../models/dnd.types'
import { useDrop } from 'react-dnd'
import { DND_ITEM_ENUM } from '../../../models/dnd.types'
import { SIDEBAR_TRANSITION_DURATION_MS } from '../MainDashboard'
import { PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM } from '../PutasideTabList/PutasideTabView'

const ProjectCreationWidget: FC = () => {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const { captureAnalytics } = useUserContext()
  const pendingTitle = useReduxSelector(selectPendingFolderCreationTitle)
  const suggestedTitles = useReduxSelector(selectSuggestedFolderCreationTitles)
  const suggestedPages = useReduxSelector(selectSuggestedFolderCreationPages)

  const [isDebounceLoading, setIsDebounceLoading] = useState<boolean>(false)
  const [debouncedFolderName, setDebouncedFolderName] = useState<string>('')

  const nextNameChangeUpdateRef = useRef<NodeJS.Timeout | null>(null)
  const pendingPages = useReduxSelector(selectPendingFolderCreationPages)

  const [isSidebarFullyExpanded, setIsSidebarFullyExpanded] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => {
      setIsSidebarFullyExpanded(true)
    }, SIDEBAR_TRANSITION_DURATION_MS)
  }, [])

  const [
    getProjectRecommendations,
    { data: recommendationsQueryData, isFetching: isFetchingRecommendationsQuery },
  ] = useLazyGetProjectRecommendationsQuery({
    refetchOnFocus: false,
  })

  const isLoadingRecommendations = isDebounceLoading || isFetchingRecommendationsQuery

  useEffect(() => {
    getProjectRecommendations({
      title: debouncedFolderName,
      pages: pendingPages,
    })
  }, [debouncedFolderName, pendingPages, getProjectRecommendations])

  useEffect(() => {
    if (!recommendationsQueryData) {
      return
    }

    dispatch(setSuggestedFolderCreationTitles(recommendationsQueryData.titles ?? []))
    dispatch(setSuggestedFolderCreationPages(recommendationsQueryData.pages))
  }, [dispatch, recommendationsQueryData])

  const handleFolderNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setPendingFolderCreationTitle(e.target.value))

      if (nextNameChangeUpdateRef.current) {
        clearTimeout(nextNameChangeUpdateRef.current)
      }

      setIsDebounceLoading(true)
      nextNameChangeUpdateRef.current = setTimeout(() => {
        setDebouncedFolderName(e.target.value)
        setIsDebounceLoading(false)
      }, 1000)
    },
    [dispatch],
  )

  const handleClickTitleSuggestion = useCallback(
    (title: string) => {
      dispatch(setPendingFolderCreationTitle(title))
      setDebouncedFolderName(title)

      captureAnalytics(`project_creation_widget:title_suggestion_click`, {
        title,
        suggestedTitles,
      })
    },
    [captureAnalytics, dispatch, suggestedTitles],
  )

  const [createProjectMutation] = useCreateProjectMutation()

  const { projectConfig } = useFeatureFlagContext()
  const maxNumProjects = projectConfig.maxNumProjects
  const { data: projects } = useGetActiveProjectsQuery(undefined)
  const isProjectLimitReached = !projects || projects.length >= maxNumProjects
  //TODO: Handle project page limit everywhere necessary

  const handleCreateProject = useCallback(
    async (params: {
      tablistPages: TablistPageType[]
      title?: string
      order?: number
    }): Promise<ProjectType | undefined> => {
      const { tablistPages, title, order } = params
      if (isProjectLimitReached) {
        //TODO: Prevent user from even getting into this widget
        return
      }

      const result = await createProjectMutation({
        tablistPages,
        title,
        existingProjects: projects,
        order,
        titlePrefix: 'Folder',
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      return result
    },
    [isProjectLimitReached, createProjectMutation, projects],
  )

  const handleClickSave = useCallback(async () => {
    const project = await handleCreateProject({
      tablistPages: pendingPages,
      title: pendingTitle,
    })

    captureAnalytics(`project_creation_widget:save_click`, {
      numPages: pendingPages.length,
      title: pendingTitle,
      pages: pendingPages,
    })

    if (project?.id) {
      navigate(`/folders/${project?.id}`)
    }

    dispatch(cancelFolderCreationMode())
  }, [handleCreateProject, pendingPages, pendingTitle, captureAnalytics, dispatch, navigate])

  const handleClickCancel = useCallback(() => {
    captureAnalytics(`project_creation_widget:cancel_click`, {
      numPages: pendingPages.length,
      title: pendingTitle,
      pages: pendingPages,
    })
    dispatch(cancelFolderCreationMode())
  }, [pendingPages, pendingTitle, captureAnalytics, dispatch])

  const isTablistPageDragging = useReduxSelector((state) => state.dnd.isTablistPageDragging)
  const [{ isDraggingOver }, connectDropTarget] = useDrop(
    () => ({
      accept: [
        DND_ITEM_ENUM.TABLIST_PAGE,
        DND_ITEM_ENUM.TABLIST_PAGES,
        DND_ITEM_ENUM.SMART_SESSION,
      ],
      collect: (monitor) => ({
        isDraggingOver: monitor.isOver(),
      }),
      canDrop: (_payload: DnDItemPayload) => {
        return true
      },
      drop: (payload: DnDItemPayload) => {
        //TODO: Handle project page limit
        // if (isProjectLimitReached) {
        //   handleProjectLimitError()
        //   return { status: 'ERROR: Project limit reached' }
        // }

        if (
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES
        ) {
          const payloads =
            payload.type === DND_ITEM_ENUM.TABLIST_PAGES ? payload.payloads : [payload]
          const tablistPages = payloads.map((pl) => pl.page)
          dispatch(addPendingFolderCreationPages(tablistPages))

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.SMART_SESSION) {
          const tablistPages = [...payload.session.pages]
          if (pendingTitle === '') {
            dispatch(setPendingFolderCreationTitle(payload.session.name))
          }
          dispatch(addPendingFolderCreationPages(tablistPages))
          return { status: 'SUCCESS' }
        }

        return { status: 'ERROR' }
      },
    }),
    [dispatch, pendingTitle],
  )

  return (
    <Flex
      direction="column"
      w="100%"
      h="100%"
      overflow="hidden"
      p="24px"
      borderRadius="16px"
      bg="#FFF"
      boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.12)"
    >
      <Text
        as="h2"
        ml="6px"
        mb="24px"
        color="#000"
        fontSize="16px"
        fontWeight="600"
        lineHeight="24px"
      >
        New Folder
      </Text>

      <Text ml="6px" mb="10px" color="#A7A7A7" fontSize="14px" fontWeight="600" lineHeight="22px">
        Name
      </Text>

      <Input
        value={pendingTitle}
        onChange={handleFolderNameChange}
        placeholder="Enter folder name"
        mb={4}
      />

      {!pendingTitle && pendingPages.length === 0 && (
        <Box mb="24px">
          <Flex ml="6px" mb="10px" w="100%" alignItems="center" position="relative">
            <Icon as={HiSparkles} mr="6px" color="#0071e3" />
            <Text color="#0071e3" fontSize="14px" fontWeight="600" lineHeight="22px">
              Recommended folders for you
            </Text>
            <Tooltip
              label={`Based on your recent browsing, Skipper AI recommends setting up the folders below`}
              placement="top"
            >
              <Flex ml="8px" alignItems="center" justifyContent="center">
                <Icon as={MdHelpOutline} color="#0071e3" />
              </Flex>
            </Tooltip>
          </Flex>

          <Wrap
            spacing="8px"
            maxHeight={isSidebarFullyExpanded ? '300px' : '0px'}
            opacity={isSidebarFullyExpanded ? 1 : 0}
            transition="all 0.6s ease-in-out"
            overflow="hidden"
          >
            {!isLoadingRecommendations &&
              isSidebarFullyExpanded &&
              suggestedTitles?.map((title, index) => (
                <WrapItem key={index}>
                  <Button
                    size="sm"
                    h="28px"
                    p="0 12px"
                    border="1px solid #0071E3"
                    background="#CCE3F9"
                    color="#0071E3"
                    borderRadius="8px"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="16px"
                    onClick={() => handleClickTitleSuggestion(title)}
                  >
                    {title}
                  </Button>
                </WrapItem>
              ))}
          </Wrap>

          {(isLoadingRecommendations || !isSidebarFullyExpanded || !suggestedTitles) && (
            <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
              <Spinner color="blue.500" size="md" speed="1s" />
            </Flex>
          )}

          {!isLoadingRecommendations && suggestedTitles && suggestedTitles.length === 0 && (
            <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
              <Text textAlign={'center'} fontSize="12px" color="#a7a7a7">
                {`No recommendations found`}
              </Text>
            </Flex>
          )}
        </Box>
      )}

      <Text ml="6px" mb="10px" color="#A7A7A7" fontSize="14px" fontWeight="600" lineHeight="22px">
        Tabs
      </Text>

      <Box ref={connectDropTarget}>
        {pendingPages.length === 0 && (
          <Box
            borderWidth="1px"
            borderStyle="dashed"
            borderColor={isTablistPageDragging ? '#0071E3AA' : '#D5D5D5'}
            borderRadius="6px"
            p={4}
            mb={4}
            textAlign="center"
            bg={'#F6F6F6'}
            color={isDraggingOver ? '#0071E3' : '#A7A7A7'}
            fontSize="14px"
            fontWeight="500"
          >
            {`Drag and drop tabs here or click "+ Add" next to any tab`}
          </Box>
        )}

        {pendingPages.length > 0 && (
          <Box mb={4}>
            {pendingPages.map((tab, index) => (
              <PutasideTabController
                key={tab.id}
                areaName={TABLIST_AREA_NAME_ENUM.FolderCreation}
                id={tab.id}
                page={tab}
                queryValue={''}
                index={index}
                numTotalResults={pendingPages.length}
                spacingVariant={PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM.FOLDER_CREATION}
                isHighlighted={false}
                isSelected={false}
                isTabAboveSelected={false}
                isTabBelowSelected={false}
                shouldShowDeleteIcon={true}
                isPendingInFolderCreation={true}
              />
            ))}
          </Box>
        )}
      </Box>

      {(!!pendingTitle ||
        pendingPages.length > 0 ||
        (suggestedPages && suggestedPages.length > 0)) && (
        <Box mb="24px">
          <Flex ml="6px" mb="10px" w="100%" alignItems="center">
            <Icon as={HiSparkles} mr="6px" color="#0071e3" />
            <Text color="#0071e3" fontSize="14px" fontWeight="600" lineHeight="22px">
              Recommended tabs
            </Text>
            <Tooltip
              label={`Based on the folder you're creating, Skipper AI recommends including the tabs below`}
              placement="top"
            >
              <Flex ml="8px" alignItems="center" justifyContent="center">
                <Icon as={MdHelpOutline} color="#0071e3" />
              </Flex>
            </Tooltip>
          </Flex>

          {!isLoadingRecommendations &&
            suggestedPages?.map((tab, index) => (
              <PutasideTabController
                key={tab.id}
                id={tab.id}
                page={tab}
                queryValue={''}
                index={index}
                numTotalResults={suggestedPages.length}
                areaName={TABLIST_AREA_NAME_ENUM.RecentlySaved}
                spacingVariant={PUTASIDE_TAB_VIEW_SPACING_VARIANT_ENUM.FOLDER_CREATION}
                onOpenMoveMenu={() => {}}
                isHighlighted={false}
                isSelected={false}
                isTabAboveSelected={false}
                isTabBelowSelected={false}
                onSelected={() => {}}
                removeSelection={() => {}}
                shouldShowFolderCreationAddIcon={true}
              />
            ))}

          {(isLoadingRecommendations || !suggestedPages) && (
            <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
              <Spinner color="blue.500" size="md" speed="1s" />
            </Flex>
          )}

          {!isLoadingRecommendations && suggestedPages && suggestedPages.length === 0 && (
            <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
              <Text textAlign={'center'} fontSize="12px" color="#a7a7a7">
                {`No recommendations found`}
              </Text>
            </Flex>
          )}
        </Box>
      )}

      <Flex w="100%" mt="auto" justifyContent="flex-end">
        <ButtonGroup>
          <Button
            variant="outline"
            h="48px"
            p="0 32px"
            border="1px solid #585858"
            borderRadius="100px"
            fontSize="16px"
            fontWeight={500}
            color="black"
            bg="white"
            _hover={{ bg: '#EBEBEB' }}
            onClick={handleClickCancel}
          >
            Cancel
          </Button>

          <Button
            variant="solid"
            h="48px"
            p="0 32px"
            borderRadius="100px"
            fontSize="16px"
            fontWeight={500}
            color="white"
            bg="black"
            _hover={{ bg: '#585858' }}
            onClick={handleClickSave}
          >
            Save
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  )
}

export default ProjectCreationWidget
